
import dayjs from "dayjs";
import { QuestionTypes } from "modules/booking/common/enums";

const mappers = {
  [QuestionTypes.WHEN]: (when) => {
    if(!when) return null;
    const str = dayjs(when.date).format("YYYY-MM-DD");
    const startTime = dayjs(`${str} ${when.start}`);
    const endTime = startTime.add(when.hours, "h");
    const date = dayjs(when.date).format("DD MMMM YYYY");
    const start = dayjs(startTime).format("h:mmA");
    const end = dayjs(endTime).format("h:mmA");
    return { date, start, end };
  },
  [QuestionTypes.CONTACT]: (contact) => {
    if(!contact) return null;
    const email = contact.email;
    const name = contact.name;
    const number = contact.number;
    return { email, name, number };
  },
  [QuestionTypes.WHERE]: (where) => {
    if(!where) return null;
    const street = [where.streetNumber, where.street].filter(Boolean).join(" ");
    const address = [where.city, where.state, where.postcode].filter(Boolean).join(", ");
    const country = where.country;
    return { street, address, country };
  },
  [QuestionTypes.OPTIONS]: (options) => {
    if(!options) return null;
    const bg = options.bg;
    const guestbook = `Include Guestbook: ${options.guestbook}`;
    const props = `Include Props: ${options.props}`;
    const style = options.style;
    return { bg, guestbook, props, style };
  }
}

export default (data) => {
  const rtn = {};
  if(!data) return rtn;
  Object.keys(data).forEach(key => rtn[key] = mappers[key](data[key]))
  return rtn;
}


