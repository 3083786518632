import React from "react";
import { Helmet } from "react-helmet";

const HeadTags = (props) => {
  const { title, description, url } = props;
  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content={description}
      />
      <meta 
        name="url" 
        content={url}
      />
      <meta 
        property="og:url" 
        content={url}
      />
      <meta 
        property="og:description" 
        content={description}      
      />
    </Helmet>
  )
}

export default HeadTags;