import React from "react";
import { makeStyles, Button, GridList, GridListTile } from "@material-ui/core"
import { useBreakpoint } from "common/hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 !important",
  },
  tile: {
    cursor: "pointer",
    "& .MuiGridListTile-tile:before": {
      content: "''",
      transition: "opacity 0.3s",
      position: "absolute",
      top: 0,
      width: "100%",
      height: "100%",
      opacity: "0",
      background: "#000",
      color: "#000",
      zIndex: 99
    },
    "& .action": {
      alignItems: "center",
      transition: "opacity 0.3s",
      opacity: 0,
      display: "flex",
      height: "100%",
      justifyContent: "center",
      position: "absolute",
      top: 0,
      width: "100%",
      zIndex: 100,
      textAlign: "center",
      flexDirection: "column"
    },
    "& .title": {
      borderBottom: `solid 2px ${theme.palette.white.main}`,
      color: theme.palette.white.main,
      fontSize: "28px",
      fontWeight: "bold",
      lineHeight: "46px",
      marginBottom: theme.spacing(1.5),
      padding: `0 ${theme.spacing(1)}px`
    },
    "&:hover": {
      "& .MuiGridListTile-tile:before": {
        opacity: "0.5",
      },
      "& .img": {
        filter: "blur(4px)",
      },
      "& .action": {
        opacity: 1
      },
    }
  },
}))

const EventGrid = (props) => {
  const { events, numberToRender, onClick } = props;
  const classes = useStyles();
  const breakpoint = useBreakpoint();
  const cols = { "xs": 1, "sm": 1, "md": 2, "lg": 3, "xl": 3 };

  if(!events) return null;

  const eventsToRender = numberToRender ? events.slice(0, numberToRender) : [...events];

  return (
    <GridList 
      cellHeight={300} 
      className={classes.root}
      cols={cols[breakpoint]}
    >
      {eventsToRender
        .map((event, i) => (
          <GridListTile 
            key={i}
            className={classes.tile}
          >
            <img alt="Forever Snaps" className="img" src={event.postcard} />
            <div className="action"> 
              <div className="title">{event.title}</div> 
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => onClick(event)}
              > 
                VIEW EVENT 
              </Button>
            </div>
          </GridListTile>
        ))
      }
    </GridList> 
  )
}

export default EventGrid;