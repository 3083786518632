import React from "react";
import { Menu, Splash, Footer, HeadTags } from "common/components";

import Intro from "./Intro";
import OurProcess from "./OurProcess";
import GetInTouch from "./GetInTouch";
import PerfectPackage from "./PerfectPackage";
import RecentEvents from "./RecentEvents";

const Landing = () => {
  return (
    <div> 
      <HeadTags 
        title="Forever Snaps | Photo Booth Hire Sydney"
        url="https://foreversnaps.com.au"
        description="Affordable, competitive and cheap open air photo booth hire in Sydney. Passionate about making your next event memorable and unforgettable."
      />
      <Menu />
      <Splash />

      <Intro />
      <OurProcess />

      <RecentEvents />
      <PerfectPackage />
      <GetInTouch />

      <Footer />
    </div>
  )  
}

export default Landing;
