import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    background: props => props.background || theme.palette.primary.main,
    borderRadius: "40px 10px",
    color: theme.palette.white.main,
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    "&:last-child": {
      marginBottom: 0
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row"
    }
  },
  number: {
    fontSize: "70px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "80px",
      paddingRight: theme.spacing(4),
    },
  },
  content: {
    borderTop: `solid 2px ${theme.palette.white.main}`,
    paddingTop: theme.spacing(3),
    position: "relative",

    [theme.breakpoints.up("sm")]: {
      borderTop: "none",
      borderLeft: `solid 2px ${theme.palette.white.main}`,
      paddingLeft: theme.spacing(5),
      paddingTop: 0,
    }
  }
}));

const Step = (props) => {
  const { number, title, background, children } = props;
  const classes = useStyles({ background });

  return (
    <div className={classes.container}>
      <div className={classes.number}> {number} </div>
      <div  className={classes.content}>
        <Typography variant="h3"> {title} </Typography>
        {children}
      </div>
    </div>
  )
}

export default Step;