import React from "react";
import { makeStyles } from "@material-ui/core"
import { GoogleMap, Marker } from "@react-google-maps/api";

const useStyles = makeStyles((theme) => ({
  map: {
    width: "100%",
    position: props => props.fullscreen ? "absolute" : "relative",
    zIndex: props => props.fullscreen ? 900 : "auto",
    height: props => props.fullscreen ? `calc(100vh - (${theme.components.footer.height}))` : "300px",
    opacity: props => props.fullscreen ? 0.2 : 1
  }
}));

const Map = ({
  fullscreen = false, 
  zoom = 14,
  longitude = 150.9963723,
  latitude = -33.8156049
}) => {
  const classes = useStyles({ fullscreen });
  const offset = .004;
  const center = { lat: latitude + offset, lng: longitude + offset };
  const position = { lat: latitude, lng: longitude };

  return (
    <GoogleMap
      clickableIcons={false}
      zoom={zoom}
      center={center}
      mapContainerClassName={classes.map}
      options={{
        mapTypeControl: false,
        zoomControl: false,
        fullscreenControl: false,
        minZoom: 5
      }}
    >
      <Marker position={position} />
    </GoogleMap>
  )
}

export default Map;