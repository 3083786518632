import axios from "axios";
import publicIp from "public-ip";

const emailService = {};

const base = {
  "development": "http://localhost:12345",
  "production": "https://api.foreversnaps.com.au" 
}

axios.interceptors.request.use(async (config) => {
  const ip = await publicIp.v4();
  config.headers["x-fs-ip"] = ip;
  return config;
});

emailService.send = async (name, email, contact, enquiry, message, token) => {
  const baseUrl = base[process.env.NODE_ENV];
  const resource = "email"

  return axios.post(`${baseUrl}/${resource}`, {
    name: name,
    email: email,
    contact: contact,
    enquiry: enquiry,
    message: message,
    token: token
  });
}

export default emailService;