import dayjs from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";

// extend dayjs
dayjs.extend(objectSupport);

const useTimes = (start) => {
  const timeStart = start * 2;
  const timeLength = (48 - timeStart);

  // calculate times and create kvp
  const times = Array.from({ length: timeLength }, (_, hour) => {
    const time = dayjs({
      hour: Math.floor((hour + timeStart) / 2),
      minute: ((hour + timeStart) % 2 === 0 ? 0 : 30)
    }).format("hh:mm A");
    return { value: time, label: time };
  });

  // calculate hours and create kvp
  const hours = Array.from({ length: start }, (_, hour) => {
    const h = hour + 2;
    return { value: h, label: `${h} hrs`};
  });

  return { times, hours };
}

export default useTimes;