import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { Link } from "react-router-dom";
import cn from "classnames";
import { Wrapper, LogoMin } from "common/components";
import { useScroll } from "common/hooks";
import DesktopMenuItems from "./DesktopMenuItems";
import MobileMenuItems from "./MobileMenuItems";

const useStyles = makeStyles((theme) => ({
  menu: {
    backgroundColor: "rgba(33, 33, 33, 1)",
    boxShadow: `rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px`,
    display: "flex",
    justifyContent: "center",
    transition: "all 0.2s ease",
    padding: `${theme.spacing(1.5)}px 0`,
    position: "fixed",
    width: "100%",
    zIndex: 1000,

    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(1.5)}px 0 0 0`,
    },
  },
  top: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    boxShadow: "none",
    padding: `${theme.spacing(2.5)}px 0`,
  },
  wrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    padding: theme.spacing(0.5),

    [theme.breakpoints.up("md")]: {
      padding: 0
    },
  },
  logo: {
    flex: 1,
    marginLeft: theme.spacing(2)
  }
}))

const Menu = ({
  width
}) => {
  const { scrollTop } = useScroll();
  const classes =  useStyles();

  const items = [
    { title: "About", to: "/about-us", selected: false },
    { title: "How it works", to: "/how-it-works", selected: false },
    { title: "Events", to: "/events", selected: false },
    { title: "Packages", to: "/packages", selected: false },
    { title: "Contact", to: "/contact", selected: false },
    { title: "Book Now", to: "/booking/when", selected: false }
  ];

  const menuItems = isWidthUp("md", width) ? 
    <DesktopMenuItems items={items} /> : 
    <MobileMenuItems items={items} open={false} />

  return (
    <Box boxShadow={3} className={cn({[classes.menu]: true, [classes.top]: scrollTop === 1})}>
      <Wrapper className={classes.wrapper}>
        <div className={classes.logo}>
          <Link to="/">
            <LogoMin size={64} />
          </Link>
        </div>
        {menuItems}
      </Wrapper>
    </Box>
  )
}

export default withWidth()(Menu);