import React from "react";
import { makeStyles, Grid } from "@material-ui/core"
import { Section, GotAQuestion, Map } from "common/components";

const useStyles = makeStyles(() => ({
  mapContainer: {
    zIndex: 2,
  },
  map: {
    height: "300px",
    width: "100%"
  }
}))

const GetInTouch = (props) => {
  const classes = useStyles();

  return (
    <Section
      id="contact"
      heading="Get in touch"
      blurb="Not too sure what youre after? You can reach us below"
    >
      <Grid item xs={12} className={classes.mapContainer}>
        <Map />
      </Grid>
      <Grid item xs={12}>
        <GotAQuestion />
      </Grid>
    </Section>
  )
}

export default GetInTouch;