const overrideButton = (theme) => {
  theme.overrides["MuiButton"] = {
    root: {
      "&.Mui-disabled": {
        cursor: "not-allowed"
      }
    },
    contained: {
      "&&:hover.Mui-disabled": {
        backgroundColor: "rgba(220, 30, 94, 0.7)",
      }
    },
    containedPrimary: {
      "&.Mui-disabled": {
        backgroundColor: "rgba(220, 30, 94, 0.7)",
        color: theme.palette.white.main
      }
    },
    textPrimary: {
      "&.Mui-disabled": {
        color: "rgba(220, 30, 94, 0.7)",
      }
    }
  };
}

export default overrideButton;