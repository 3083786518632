import React from "react";
import { makeStyles, useTheme, Grid, SvgIcon } from "@material-ui/core";
import { Menu, HeadTags, HalfSplash, Footer, Map, Section, Wrapper, MakeABooking } from "common/components";
import { Facebook as FacebookIcon, Instagram as InstagramIcon, Email as EmailIcon, Smartphone as SmartphoneIcon } from "@material-ui/icons";
import splash from "assets/contact-splash.jpg";
import ContactForm from "./ContactForm";

const useStyles = makeStyles(theme => ({
  space: {
    marginBottom: theme.spacing(4)
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    flexDirection: "column",
    margin: theme.spacing(0.5),
    background: props => props.color || theme.palette.primary.main,
    textDecoration: "none"
  },
  icon: {
    fontSize: "34px",
    marginBottom: theme.spacing(1)
  },
  label: {
    fontSize: "16px"
  }
}))

const Item = (props) => {
  const { icon, label, color, href } = props;
  const classes = useStyles({ color });

  return (
    <a href={href} target="_target" className={classes.item}>
      <SvgIcon component={icon} className={classes.icon} />
      <div className={classes.label}> {label} </div>
    </a>
  )
}

const Contact = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div>
      <HeadTags 
        title="Contact | Forever Snaps"
        url="https://foreversnaps.com.au/contact"
        description="We would love to hear from you!"
      />
      <Menu />
      <HalfSplash 
        background={splash}
        blurb="Contact"
        tag="We would love to hear from you!"
      />
      
      <ContactForm />

      <Wrapper className={classes.space}>
        <Section
          heading="Get in touch"
          blurb="Follow us on Facebook and Instagram or contact us directly"
        >
          <Grid container>
            <Grid item xs={12} sm={6} md={3}>
              <Item 
                icon={FacebookIcon} 
                label="/foreversnapsx" color={theme.palette.primary.main}
                href="https://facebook.com/foreversnapsx"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Item 
                icon={InstagramIcon} 
                label="@foreversnapsx" 
                color={theme.palette.blue.main} 
                href="https://instagram.com/foreversnapsx"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Item 
                icon={SmartphoneIcon} 
                label="0405 392 792" 
                color={theme.palette.yellow.main} 
                href="tel:0405392792"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Item 
                icon={EmailIcon} 
                label="info@foreversnaps.com.au" 
                color={theme.palette.green.main} 
                href="mailto:info@foreversnaps.com.au"
              />
            </Grid>                        
          </Grid>
        </Section>
      </Wrapper>

      <Map />
      <MakeABooking />

      <Footer />
    </div>
  )  
}

export default Contact;