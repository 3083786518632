import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles, useTheme, useMediaQuery, IconButton, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Close as CloseIcon } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { Menu, Footer } from "common/components";
import { bookingActions, bookingSelectors, QuestionTypes } from "modules/booking/common";
import { BookingOverview } from "../booking-overview";
import QuestionFactory from "./QuestionFactory";

const useStyles = makeStyles(theme => ({
  root: {
    background: "#fafafa",
    display: "flex",
    height: `calc(100vh - ${theme.components.footer.height})`,
  },
  content: {
    flex: 1,
  },
}))

const BookingQuestions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const success = useSelector(state => state.booking.success);
  const error = useSelector(state => state.booking.error);
  const currentQuestion = useSelector(bookingSelectors.questionByRoute(location.pathname));
  const nextQuestion = useSelector(bookingSelectors.questionNext(currentQuestion));
  const answers = useSelector(bookingSelectors.answers);
  const currentAnswers = useSelector(bookingSelectors.answersByQuestion(currentQuestion.type));
  const showBookingOverview = useSelector(bookingSelectors.bookingOverviewShow());
  const classes = useStyles({ showBookingOverview });

  useEffect(() => {
    if(!answers) history.push("/booking/when");
  }, []);

  useEffect(() => {
    if(success) history.push("/booking/thank-you");
  }, [success]);
  
  const handleAnswerQuestion = (questionAnswers) => {
    // if we are on confirmation, submit the booking
    if(currentQuestion.type === QuestionTypes.CONFIRM) {
      dispatch(bookingActions.submitBooking(answers));
      return;
    }
    
    // answer the question and navigate to the next one
    dispatch(bookingActions.answerQuestion({
      answers: questionAnswers,
      question: currentQuestion.type
    }));
    history.push(nextQuestion.route);
  }

  const handleOnSnackbarClose = () => {
    dispatch(bookingActions.closeError());
  }

  return (
    <div>
      <Menu />
      <Snackbar 
        open={error}
        autoHideDuration={5000}
        onClose={handleOnSnackbarClose}
      >
        <Alert 
          severity="error"
          onClose={handleOnSnackbarClose}
          action={
            <IconButton onClick={handleOnSnackbarClose} color="inherit" size="small">
              <CloseIcon />
            </IconButton>
          }
        > 
          Uh oh, something went wrong and your booking was not sent. Please give us a call
        </Alert>
      </Snackbar>
      <div className={classes.root}>
        <div className={classes.content}>
          <QuestionFactory 
            type={currentQuestion.type}
            answers={currentAnswers}
            onSuccess={handleAnswerQuestion}
          />
        </div>
        <BookingOverview />
      </div>
      <Footer />      
    </div>
  )
}

export default BookingQuestions;

