import React, { lazy, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { QuestionTypes } from "modules/booking/common/enums";

const QuestionFactory = (props) => {
  const { type } = props;
  const [question, setQuestion] = useState(null);
  const kvp = {
    [QuestionTypes.WHEN]: "QuestionWhen",
    [QuestionTypes.WHERE]: "QuestionWhere",
    [QuestionTypes.OPTIONS]: "QuestionOptions",
    [QuestionTypes.CONTACT]: "QuestionContact",
    [QuestionTypes.CONFIRM]: "QuestionConfirm"
  }

  useEffect(() => {
    const val = kvp[type];
    const Component = lazy(() => import(`./${val}`));
    setQuestion(
      <Component 
        key={nanoid()}
        type={type}
        {...props}
      />
    )
  }, []);

  return (
    <React.Suspense fallback="loading">
      {question}
    </React.Suspense>
  );
}

export default QuestionFactory;