const overrideTextField = (theme) => {
  theme.overrides["MuiTextField"] = {
    root: {
      width: "100%",
    }
  };
  theme.overrides["MuiInputBase"] = {
    input: {
      fontSize: "14px"
    }
  };
  theme.overrides["MuiOutlinedInput"] = {
    root: {
      backgroundColor: theme.palette.white.main,
    },
    input: {
      fontSize: "14px"
    }
  };
  theme.overrides["MuiFilledInput"] = {
    root: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      width: "100%",
      backgroundColor: theme.palette.white.main,
      transition: "none",
      "&:hover, &$focused": {
        backgroundColor: theme.palette.white.main,
      },
    },
    input: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      border: `solid 1px ${theme.palette.primary.main}`
    },
    underline: {
      "&:after": {
        borderBottom: "none"
      }
    }
  };
}

export default overrideTextField;