import React from "react";
import { makeStyles } from "@material-ui/core";
import { Wrapper } from "common/components";
import { Facebook as FacebookIcon, Instagram as InstagramIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.black.main,
    color: theme.palette.white.main,
    height: theme.components.footer.height
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  text: {
    flex: 1,
    padding: `0 ${theme.spacing(1)}px`
  },
  social: {
    flexBasis: "80px",
    paddingLeft: theme.spacing(2),
    "& a": {
      color: theme.palette.white.main
    }
  },
  icon: {
    fontSize: "28px",
    marginRight: theme.spacing(0.5)
  }
}))

const Footer = (props) => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}> 
      <Wrapper className={classes.content}>
        <div className={classes.social}> 
          <a href="https://www.facebook.com/foreversnapsx/" rel="noopener noreferrer" target="_blank"><FacebookIcon className={classes.icon} /></a>
          <a href="https://www.instagram.com/foreversnapsx/" rel="noopener noreferrer" target="_blank"><InstagramIcon className={classes.icon} /></a>
        </div>
        <div className={classes.text}>
          Copyright © 2021 - Forever Snaps
        </div>
      </Wrapper>
    </footer>
  )
}

export default Footer;