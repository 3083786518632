import React, { useEffect, useState } from "react";
import { makeStyles, FormControl, Select, MenuItem } from "@material-ui/core";
import cn from "classnames";
import { ValidationError } from "common/components";

const useStyles = makeStyles(theme => ({
  defaultValue: {
    color: theme.palette.grey.main
  },
  question: {
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  item: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    textTransform: "capitalize"
  },
  option: {
    flex: 1
  }
}))

const Dropdown = ({
  id,
  name,
  value,
  classNames,
  error,
  items,
  question,
  showQuestion = true,
  helperText,
  fullWidth = false,
  onChange
}) => {
  const [ q, setQ ] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setQ(`${question}:`);
  }, [question])

  return (
    <FormControl 
      error={error} 
      fullWidth={fullWidth}
    >
      <Select
        id={id}
        name={name}
        fullWidth={fullWidth}
        className={cn({
          [classes.defaultValue]: !value, 
          [classNames]: classNames 
        })}
        value={value}
        onChange={onChange}
        onOpen={() => setQ(null)}
        onClose={() => setQ(`${question}:`)}
        variant="outlined"
        displayEmpty
      >
        <MenuItem disabled value=""> {question} </MenuItem>
        {items.map((i, index) => (
          <MenuItem key={index} value={i.value}>
            <div className={classes.item}>
              <div className={cn({[classes.option]: true})}>
                {(q && showQuestion) && <span className={classes.question}> {q} </span>}
                <span> {i.label} </span>
              </div>
              {i.extra && <div> {i.extra} </div>}
            </div>
          </MenuItem>
        ))}
      </Select>
      {helperText && <ValidationError error={helperText} />}
    </FormControl>
  )
}

export default Dropdown;