import React from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    cursor: "pointer"
  },
  heading: {
    display: "flex",
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    backgroundColor: "#212121",
    fontSize: "16px",
    fontWeight: "bold",
    alignItems: "center"
  },
  icon: {
    color: "#ED91B0"
  },
  value: {
    color: "#ED91B0",
    flex: 1,
    position: "relative",
    top: "1px",
    marginLeft: theme.spacing(1)
  },
  details: {
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
  }
}))

const BookingOverviewItem = (props) => {
  const { heading, icon, children, onClick } = props;
  const classes = useStyles();
 
  return (
    <div className={classes.root} onClick={onClick}> 
      <div className={classes.heading}>
        <SvgIcon component={icon} fontSize="small" className={classes.icon} />
        <div className={classes.value}>{heading}</div>
      </div>
      <div className={classes.details}>
        {children}
      </div>
    </div> 
  )
}

export default BookingOverviewItem;