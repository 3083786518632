import React from "react";
import { Grid } from "@material-ui/core";
import { Section, Wrapper, Card } from "common/components";
import { 
  Party as PartyIcon
} from "common/icons";
import {
  List as ListIcon, 
  Check as CheckIcon, 
  Brush as BrushIcon, 
} from "@material-ui/icons";

const OurProcess = (props) => {
  return (
    <Wrapper>
      <Section
        heading="Our process"
        blurb="Keeping it simple and seamless"
      >
        <Grid container>
          <Grid item xs={12} sm={6} md={3}>
            <Card 
              icon={ListIcon} 
              title="Select a package" 
              description="Begin by selecting one of our packages that best suits your event. We can also customise a package for needs."
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card 
              icon={BrushIcon} 
              title="Customise event" 
              description="Tell us about your event and we will create you a customised photobooth template. You also get to select a backdrop to use for your event"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card 
              icon={CheckIcon} 
              title="Confirmation" 
              description="We will confirm all the details of your photobooth package, photo template and backdrop choice. If everything is all good, we will lock it in!"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card 
              icon={PartyIcon} 
              title="Party time" 
              description="Sit back, relax and let us handle the rest. We will setup, operate the booth and packup to make the experience as easy as possible"
            />
          </Grid>                        
        </Grid>
      </Section>
    </Wrapper>
  )
}

export default OurProcess;