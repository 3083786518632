import React, { useEffect, useState } from "react";
import { makeStyles, Drawer } from "@material-ui/core";
import { Close as CloseIcon, Menu as MenuIcon } from "@material-ui/icons";
import cn from "classnames";
import { LogoFull } from "common/components";
import MenuItem from "./MenuItem";

const useStyles = makeStyles((theme) => ({
  burger: {
    marginRight: theme.spacing(2)
  },
  drawer: {
    backgroundColor: "transparent",
    overflowY: "visible",
    //width: "100%"
  },
  items: {
    backgroundColor: "#000",
    width: "250px",
    height: "100vh",
    fontWeight: "bold",
  },
  logo: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  close: {
    display: "flex",
    position: "absolute",
    padding: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",
    right: "250px",
    background: "#000",
    zIndex: "10000"
  },
  icon: {
    color: theme.palette.white.main,
    fontSize: "42px"
  }, 
}))

const MobileMenuItems = (props) => {
  const { items, open } = props;
  const classes =  useStyles();

  const [ isOpen, setIsOpen ] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [ open ])

  const onMenuClick = () => {
    setIsOpen(true);
  }

  const onCloseClick = () => {
    setIsOpen(false)
  }

  const onMenuItemClick = () => {
    setIsOpen(false)
  }

  return (
    <>
      <MenuIcon 
        className={cn({[classes.icon]: true, [classes.burger]: true })}
        onClick={onMenuClick}
      />
      <Drawer 
        anchor="right" 
        open={isOpen} 
        className={classes.drawer} 
        onClose={() => setIsOpen(false)}
        PaperProps={{ className: [classes.drawer] }}
      >
        <div className={classes.items}>
          <div className={classes.logo}>
            <LogoFull />
          </div>
          {items.map((i, index) => 
            <MenuItem 
              key={index}
              title={i.title} 
              selected={i.selected} 
              to={i.to}
              onClick={onMenuItemClick}
            />)
          }
        </div>
        <div className={classes.close}>
          <CloseIcon 
            className={classes.icon}
            onClick={onCloseClick}
          />
        </div>
      </Drawer>
    </>
  )
}

export default MobileMenuItems;