
let palette = (theme) => {
  theme.palette.primary = {
    main: "#DC1E5E",
    dark: "#f06292"
  };
  theme.palette.yellow = {
    main: "#f9a825"
  };
  theme.palette.blue = {
    main: "#0288d1"
  };
  theme.palette.green = {
    main: "#689f38"
  };
  theme.palette.white = {
    main: "#FFFFFF"
  };
  theme.palette.black = {
    main: "#000000"
  };
  theme.palette.grey = {
    light: "#DEDEDE",
    main: "#BDBDBD",
    dark: "#333333"
  };
  theme.palette.blueGrey = {
    light: "#eceff1",
    dark: "#37474f"
  };
  theme.palette.success = {
    light: "#e8f5e9",
    main: "#a5d6a7",
    dark: "#388e3c"
  };
  theme.palette.error = {
    light: "#ffebee",
    main: "#ef9a9a",
    dark: "#d32f2f"
  }
}

export default palette