import React, { useState } from "react";
import { makeStyles, GridList, GridListTile, Dialog, SvgIcon } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import { Image } from "common/components";
import { useBreakpoint } from "common/hooks";
import { Carousel } from 'react-responsive-carousel';
import { isMobile } from "react-device-detect";
import cn from "classnames";

const useStyles = makeStyles(theme => ({
  modal: {
    maxWidth: "1200px",
    display: "flex",
    justifyContent: "center",
    margin: 0,
    borderRadius: 0,
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(4),
      borderRadius: 5,
    }
  },
  gallery: {
    overflow: "hidden",
    "& .carousel-status": {
      background: theme.palette.grey.dark,
      fontSize: "12px",
      right: theme.spacing(1.5),
      padding: "6px 12px",
      borderRadius: "15px",
      zIndex: 210
    }
  },
  next: {
    right: 0
  },
  prev: {
    left: 0
  },
  arrow: {
    position: "absolute",
    top: 0,
    bottom: 0,
    opacity: 0,
    background: "rgba(255,255,255,0.5)",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "opacity 0.3s",
    border: "none",
    outline: "none",
    zIndex: 200,
    "&:hover": {
      opacity: 1
    }
  },
  icon: {
    fontSize: "32px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "48px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "64px",
    }
  }
}))

const Gallery = (props) => {
  const { images } = props;
  const [ open, setOpen ] = useState(false);
  const [ selectedIndex, setSelectedIndex ] = useState(0);
  const classes = useStyles();

  const breakpoint = useBreakpoint();
  const cols = { "xs": 1, "sm": 2, "md": 2, "lg": 3, "xl": 3 };

  const handleClick = (i) => {
    setOpen(true);
    setSelectedIndex(i)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const renderArrow = (onClickHandler, hasCheck, label, icon, arrowClass) => {
    if(!hasCheck || isMobile) return;

    return (
      <button 
        title={label} 
        className={cn({ [classes.arrow]: true, [arrowClass]: true })}
        onClick={onClickHandler}
      >
        <SvgIcon component={icon} className={classes.icon} />
      </button>
    )
  }

  return (
    <>
      <GridList 
        cellHeight={350} 
        className={classes.root}
        cols={cols[breakpoint]}
      >
        {images.map((img, i) => (
          <GridListTile 
            key={i}
            className={classes.tile}
          >
            <Image 
              src={img} 
              className="MuiGridListTile-imgFullHeight"
              onClick={() => handleClick(i)}
            />
          </GridListTile>
        ))}
      </GridList>
      <Dialog 
        open={open}
        onClose={handleClose} 
        fullScreen={false}
        PaperProps={{ className: classes.modal }}
      >
        <div className={classes.gallery}>
          <Carousel 
            showThumbs={false} 
            showIndicators={false}
            showStatus={true}
            swipeable={true}
            useKeyboardArrows={true}
            dynamicHeight={true}
            statusFormatter={(current, total) => `${current}/${total}`}
            renderArrowNext={(onClickHandler, hasNext, label) => renderArrow(onClickHandler, hasNext, label, ArrowForwardIcon, [classes.next])}
            renderArrowPrev={(onClickHandler, hasPrev, label) => renderArrow(onClickHandler, hasPrev, label, ArrowBackIcon, [classes.prev])}
            selectedItem={selectedIndex}
          >
            {images.map((img, i) => (
              <img alt="Forever Snaps" key={i} className="img" src={img} />
            ))}
          </Carousel>
        </div>
      </Dialog>
    </>
  )
}

export default Gallery