import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, Slide, Typography } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  header: {
    alignItems: "center",
    backgroundColor: "rgba(33, 33, 33, 1)",
    boxShadow: `rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px`,
    display: "flex",
    height: "64px",
    transition: "all 0.2s ease",
    padding: `${theme.spacing(1.5)}px`,
    position: "fixed",
    width: "100%",
    zIndex: 1000,
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    color: theme.palette.white.main,
    flex: 1
  },
  icon: {
    fontSize: "32px",
    color: theme.palette.white.main,
  }
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} /> );

const FullScreen = (props) => {
  const { children, title, onClose, open } = props;
  const classes = useStyles();
  return (
    <Dialog 
      open={open} 
      fullScreen 
      hideBackdrop
      TransitionComponent={Transition}      
    >    
      <div className={classes.header}>
       <Typography variant="h1" className={classes.title}>
         {title}
       </Typography>
       <CloseIcon className={classes.icon} onClick={onClose} />
      </div>    
      <div className={classes.container}>
        {children}
      </div>
    </Dialog>
  )
}

export default FullScreen;