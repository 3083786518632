import React from "react";
import { useHistory } from "react-router-dom";
import { Section, Wrapper, EventGrid } from "common/components";
import { events } from "common/data";

const RecentEvents = () => {
  const history = useHistory();

  const handleEventClick = (event) => {
    history.push(`/event-detail/${event.key}`);
  }

  return (
    <Wrapper>
      <Section
        id="recent-events"
        heading="Recent events"
        blurb="A showcase of a few events we have done recently"
      >
        <EventGrid 
          events={events}
          numberToRender={6}
          onClick={handleEventClick}
        />
      </Section>
    </Wrapper>  
  )
}

export default RecentEvents;