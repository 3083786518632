import * as Yup from "yup";

const ContactFormValidation = Yup.object().shape({
  name: Yup
    .string()
    .required("Please provide your name"),
  email: Yup
    .string()
    .required("Please provide your email")
    .email("Please enter a valid email"),
  contact: Yup 
    .string()
    .required("Please provide your contact number"),
  enquiry: Yup
    .string()
    .required("Please select an enquiry type"),        
  message: Yup
    .string()
    .required("Please provide a message")
});

export default ContactFormValidation;