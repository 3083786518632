
const images = require.context("../../assets/backgrounds", true, /\.jpg$/);

const backgrounds = images.keys().map((value, i) => {
  const k = value.replace("./", "").replace(".jpg", "");

  const name = k.split("-")
    .map(x => x.charAt(0).toUpperCase() + x.slice(1))
    .join(" ");
  
  return {
    key: `BG${i + 1}`,
    title: name,
    image: images(value)
  }
})

export default backgrounds;