import React from "react";
import { useHistory } from "react-router-dom";
import { Menu, HeadTags, HalfSplash, Footer, Section, Wrapper, Pricing, MakeABooking } from "common/components";
import splash from "assets/packages-splash.jpg";
import WhatIsIncluded from "./WhatIsIncluded";
import OptionalExtras from "./OptionalExtras";

const Packages = () => {
  const history = useHistory();

  return (
    <div>
      <HeadTags 
        title="Packages | Forever Snaps"
        url="https://foreversnaps.com.au/packages"
        description="We have little something for everyone"
      />
      <Menu />
      <HalfSplash 
        background={splash}
        blurb="Packages"
        tag="We have little something for everyone"
      />
      <Wrapper>
        <Section
          heading="Our Packages"
        >
          <Pricing 
            onButtonClick={() => history.push("/contact")}
            buttonText="Book Now"
          />
        </Section>
        <WhatIsIncluded />
        <OptionalExtras />
      </Wrapper>
      <MakeABooking marginTop="4" />
      <Footer />
    </div>
  )  
}

export default Packages;