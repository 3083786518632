import React from "react";
import { makeStyles } from "@material-ui/core";
import logo from "assets/logo-white-min.png";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: props => props.size ? `${props.size}px` : "50px",
  },
}))

const LogoMin = (props) => {
  const { size } = props;
  const classes = useStyles({ size });

  return  (
    <img alt="Forever Snaps" src={logo} className={classes.logo} />
  )
}

export default LogoMin