import imagesFactory from "./imagesFactory";

const events =  [
  { 
    key: "charlottes-5th-birthday",
    title: "Charlottes 5th Birthday",
    tag: "Superheros come together to celebrate Charlottes big day!",
    date: "November 2020",
    location: "Western Sydney",
    occasion: "Birthday Party", 
    duration: "4 hours",
  },
  { 
    key: "isabellas-1st-birthday",
    title: "Isabellas 1st Birthday",
    tag: "Elmo and the gang celebrate Isabellas first birthday!",
    date: "November 2020",
    location: "South Sydney",
    occasion: "Birthday Party", 
    duration: "4 hours",
  },
  { 
    key: "litas-36th-birthday",
    title: "Litas 36th Birthday",
    tag: "A bit of gold glamour for Lalitas 36th birthday",
    date: "November 2020",
    location: "Western Sydney",
    occasion: "Birthday Party", 
    duration: "2 hours",
  },
  { 
    key: "one-contact-christmas-party-2020",
    title: "OneContact Christmas Party",
    tag: "A Christmas party on a rooftop bar overlooking Sydney",
    date: "December 2020",
    location: "Sydney CBD",
    occasion: "Christmas Party", 
    duration: "3 hours",
  },
  { 
    key: "m-s-engagement",
    title: "M&S Engagement",
    tag: "Celebrating M&S engagement with an all white theme",
    date: "November 2020",
    location: "Western Sydney",
    occasion: "Engagement Party", 
    duration: "3 hours",
  },
  { 
    key: "ibrahim-nisa-wedding",
    title: "Ibrahim & Nisas Wedding",
    tag: "Congratulations to Ibrahim & Nisa on this beautiful wedding",
    date: "December 2020",
    location: "Renaissance",
    occasion: "Wedding", 
    duration: "4 hours",
  },
  { 
    key: "elite-supps-macq",
    title: "Elite Supps Macquarie",
    tag: "Big boy Santa taking photos and doing giveaways",
    date: "December 2020",
    location: "Macqurie Park",
    occasion: "Brand Building", 
    duration: "4 hours",
  },
  { 
    key: "jasmina-harris-wedding",
    title: "Jasmina & Harris Wedding",
    tag: "Congratulations to Jasmina & Harris on this beautiful wedding",
    date: "April 2021",
    location: "Thornton Park",
    occasion: "Wedding", 
    duration: "5 hours",
  },
  { 
    key: "katy-gareth-wedding",
    title: "Katy & Gareth Wedding",
    tag: "Congratulations to Katy & Garath on this beautiful wedding",
    date: "April 2021",
    location: "Cronulla",
    occasion: "Wedding", 
    duration: "5 hours",
  },
  { 
    key: "kevins-30th-birthday",
    title: "Kevins 30th Birthday",
    tag: "Celebrating Kevins big 30th birthday",
    date: "April 2021",
    location: "Stonecutters",
    occasion: "Birthday Party", 
    duration: "3 hours",
  },
  { 
    key: "leannes-40th-birthday",
    title: "Leannes 40th Birthday",
    tag: "Celebrating Leannes big 40th birthday",
    date: "January 2021",
    location: "Western Sydney",
    occasion: "Birthday Party", 
    duration: "4 hours",
  },
  { 
    key: "nadia-kaarel-wedding",
    title: "Nadia & Kaarels Wedding",
    tag: "Congratulations to Nadia & Kaarels on this beautiful wedding",
    date: "April 2021",
    location: "Western Sydney",
    occasion: "Wedding", 
    duration: "4 hours",
  },
  { 
    key: "brookes-30th-birthday",
    title: "Brookes 30th Birthday",
    tag: "Vikings takeover and celebrate Brookes 30th birthday",
    date: "April 2021",
    location: "Western Sydney",
    occasion: "Wedding", 
    duration: "4 hours",
  },
  { 
    key: "sarah-brendan-engagement",
    title: "Sarah & Brendans Engagement",
    tag: "Congratulations to Sarah & Brendan on their engagment",
    date: "February 2021",
    location: "South Sydney",
    occasion: "Engagement Party", 
    duration: "4 hours",
  },
]
 
export default imagesFactory(events);
