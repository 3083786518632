import React from "react";
import { makeStyles, Grid } from "@material-ui/core"
import SectionHeading from "./SectionHeading";
import cn from "classnames";

const useStyles = makeStyles((theme) => ({
  section: {
    margin: `${theme.spacing(8)}px 0 0 0`,
  }
}))

const Section = (props) => {
  const { id, heading, blurb, children, className } = props;
  const classes = useStyles();

  return (
    <Grid id={id} container className={cn({[classes.section]:true }, className)}>
      <Grid item xs={12}>
        <SectionHeading 
          heading={heading}
          blurb={blurb}
        />
      </Grid>
      {children}
    </Grid>
  )
}

export default Section;