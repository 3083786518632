import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Menu, HeadTags, HalfSplash, Footer, Section, ContentItem, GotAQuestion } from "common/components";
import splash from "assets/about-splash.jpg";

import about from "assets/about.jpg";
import bdayEvent from "assets/bday-event.png";
import christmasEvent from "assets/christmas-event.png";
import corpEvent from "assets/corp-event.png";
import privateEvent from "assets/private-event.png";

const useStyles = makeStyles(theme => ({
  content: {
    textAlign: "left",
    alignItems: "flex-start"
  },
  subHeading: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1)
  }
}))

const AboutUs = () => {
  const classes = useStyles();

  return (
    <div>
      <HeadTags 
        title="About Us | Forever Snaps"
        url="https://foreversnaps.com.au/about-us"
        description="We love photography and capturing those special moments"
      />
      <Menu />
      <HalfSplash 
        background={splash}
        blurb="About Us"
        tag="We love photography and capturing those special moments"
      />
      <Section
        heading="Who we are"
        blurb="Just a couple of Sydney-siders that enjoy making people smile"
      >
         <ContentItem 
          className={classes.content}
          image={about}
          opposite
        >
          <>
            <Typography paragraph> 
              Hey this is Bel and Ron, together we run Forever Snaps and we love to make sure that we make your event
              extra memorable and capture those special moments.
            </Typography>
            <Typography paragraph> 
              A little bit about us, Bel is a registered nurse who loves to help people and make them happy. Shes will
              go that extra distance to make sure that you have the most seamless and most enjoyable photobooth experience 
              ever.
            </Typography>
            <Typography paragraph> 
              Ron is the guy who all the technical stuff work. With a background in IT, photography and graphics design, he
              ensures that we capture all the best moments with the best possible quality. 
            </Typography>            
          </>
        </ContentItem>
      </Section>

      <Section
        heading="What we do"
        blurb="Celebrations, corporate events, seasonal events, private functions - we cater for them all!"
      >
        <ContentItem 
          className={classes.content}
          image={bdayEvent}
        >
          <>
            <Typography variant="h3"> Celebrations </Typography>
            <Typography paragraph className={classes.subHeading}>
              Birthdays, Weddings, Engagements
            </Typography>
            <Typography paragraph> 
              These are our favourite type of celebrations as we can capture happy candid moments of your guests
            </Typography>
            <Typography paragraph>
              We cater for all types of birthdays, whether you're having a kids birthday party, acknowledging adulthood for an 18th birthday, or celebrating a half century - we will ensure that we have the appropriate setup for your theme.  
            </Typography>
            <Typography paragraph>
              Planning weddings &amp; engagements can be a bit stressful, so we strive to make our process as seamless &amp; simple as possible for you.
            </Typography>
          </>
        </ContentItem>  
        <ContentItem 
          className={classes.content}
          image={corpEvent}
          opposite
        >
          <>
            <Typography variant="h3"> Corporate Events </Typography>
            <Typography paragraph className={classes.subHeading}>
              Year-end functions, Product launches, Trade shows
            </Typography>
            <Typography paragraph> 
              Say thank you to your team members by letting them let loose and take a fun memorable photo with their colleagues. It provides team bonding and encourages socializing among each other which is always a plus!
            </Typography>
            <Typography paragraph> 
              We can also help boost brand or product recognition by allowing people to take photos with your business. The prints can be branded and when distributed can help promote your business. We also offer to customise
              backdrops or props to further raise brand awareness.
            </Typography>
          </>
        </ContentItem>
        <ContentItem 
          className={classes.content}
          image={privateEvent}
        >
          <>
            <Typography variant="h3"> Private Functions </Typography>
            <Typography paragraph className={classes.subHeading}>
              Graduations, Anniversaries, Baptisms 
            </Typography>
            <Typography paragraph> 
              These events are just as momentous as birthdays or weddings. Celebrate with us and let us help capture this special day for you and your guests.
            </Typography>
            <Typography paragraph>
              We can cater for all venues types (that allow us to be there) such as schools, restaurants, boats, parks, etc. 
            </Typography>
          </>
        </ContentItem>
        <ContentItem 
          className={classes.content}
          image={christmasEvent}
          opposite
        >
          <>
            <Typography variant="h3"> Seasonal Events </Typography>
            <Typography paragraph className={classes.subHeading}>
              Christmas, New Years, Easter
            </Typography>
            <Typography paragraph> 
              Seasonal events is usually a time to celebrate with family &amp; loved ones. We have all different types of props &amp; accessories which celebrate these events. We take pride in supplying a wide range of props
              during events as we feel it really does make the photobooth fun and unique.
            </Typography>
          </>
        </ContentItem>   
      </Section>

      <GotAQuestion marginTop={4} />

      <Footer />
    </div>
  )  
}

export default AboutUs;