import React from "react";
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundImage: props => `url(${props.background})`,
    height: "50vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  intro: {
    flex: 1,
    maxWidth: "80%",
    textAlign: "center"
  },
  blurb: {
    textShadow: `4px 4px 5px ${theme.palette.blueGrey.dark}`,
    fontWeight: "bold",
    marginTop: theme.spacing(4),
    color: "#fff",
    fontSize: "38px",
    [theme.breakpoints.up("md")]: {
      fontSize: "54px"
    }
  },
  img: {
    maxWidth: "600px",
    width: "100%"
  },
  tag: {
    textShadow: `3px 3px 4px ${theme.palette.blueGrey.dark}`,
    marginTop: theme.spacing(4),
    color: "#fff",
    fontSize: "22px",
    [theme.breakpoints.up("md")]: {
      fontSize: "32px"
    }
  }
}))

const HalfSplash = (props) => {
  const { background, blurb, tag } = props;
  const classes = useStyles({ background });
  return (
    <div className={classes.header}>
      <div className={classes.intro}>
        <div className={classes.blurb}>
          {blurb}
        </div>
        <div className={classes.tag}>
          {tag}
        </div>
      </div>
    </div>
  )  
}

export default HalfSplash;
