import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import cn from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    textTransform: "uppercase",
    fontSize: "15px",
    color: theme.palette.white.main,
    fontWeight: "bold",
    display: "block",
    marginBottom: theme.spacing(0.5),
    textDecoration: "none",
    [theme.breakpoints.up("md")]: {
      display: "inline",
      fontSize: "13px",
      marginBottom: 0,
      padding: `${theme.spacing(1.75)}px ${theme.spacing(2.5)}px ${theme.spacing(2.5)}px ${theme.spacing(2.5)}px`,
    },
  },
  selected: {
    borderLeft: `solid 6px ${theme.palette.primary.main}`,
    paddingLeft: `${theme.spacing(2.25)}px`,
    
    [theme.breakpoints.up("md")]: {
      borderBottom: `solid 6px ${theme.palette.primary.main}`,
      borderLeft: "none"
    },
  },
}))

const MenuItem = (props) => {
  const { title, to, selected, onClick } = props;
  const classes = useStyles();

  return (
    <Link
      className={cn({[classes.root]: true, [classes.selected]: selected })}
      href={`${to}`}
      to={to} 
      onClick={onClick}
    > 
      {title} 
    </Link>
  )
}

export default MenuItem;