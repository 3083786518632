import EventDetail from "./event-detail/EventDetail";
import Events from "./events/Events";

const ContentRoutes = [
  {
    path: "/events/",
    component: Events
  },
  {
    path: "/event-detail/:name",
    component: EventDetail
  },
];

export default ContentRoutes;