import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Loader } from "common/components";
import cn from "classnames";
 
const useStyles = makeStyles(theme => ({
  hide: {
    display: "none"
  }
}))

const Image = (props) => {
  const [ loading, setLoading ] = useState(true);
  const classes = useStyles();
  const { src, onClick, className } = props;

  return (
    <>
      {loading && <Loader />}
      <img 
        alt="Forever Snaps"
        src={src} 
        onClick={onClick}
        onLoad={() => setLoading(false)} 
        className={cn({ [classes.hide]: loading, [className]: true })}
      />
    </>
  )
}

export default Image;