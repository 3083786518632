import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  loader: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  }
}))

const Loader = () => {
  const classes = useStyles();
  return (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  )
}

export default Loader;