import React from "react";
import { makeStyles } from "@material-ui/core";
import { ArrowUpward as ArrowUpwardIcon } from "@material-ui/icons";
import { animateScroll as scroll } from "react-scroll";
import { useScroll } from "common/hooks";
import cn from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 800,
    position: "fixed",
    bottom: 40,
    right: 40,
    backgroundColor: theme.palette.white.main,
    borderRadius: 100,
    width: 60,
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: `rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px`,
    transition: "opacity 0.3s",
    opacity: 0,
  },
  icon: {
    fontSize: "48px",
    color: theme.palette.primary.main
  },
  show: {
    opacity: 1
  }
}))

const BackToTop = (props) => {
  const classes = useStyles();
  const { scrollTop } = useScroll();

  const handleClick = () => {
    scroll.scrollToTop();
  }

  return (
    <div className={cn({ [classes.root]: true, [classes.show]: scrollTop > 0 } )}>
      <ArrowUpwardIcon className={classes.icon} onClick={handleClick} />
    </div>
  )
}

export default BackToTop;