import React from "react";
import { FormHelperText, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
  }
}))

const ValidationError = ({
  error
}) => {
  const classes = useStyles();
  if(!error) return null;
  return (
    <FormHelperText className={classes.text}>{error}</FormHelperText>
  )
}

export default ValidationError