import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Drawer } from "@material-ui/core";
import { bookingActions, bookingSelectors } from "modules/booking/common";
import BookingOverviewWhen from "./BookingOverviewWhen";
import BookingOverviewWhere from "./BookingOverviewWhere";
import BookingOverviewOptions from "./BookingOverviewOptions";
import BookingOverviewContact from "./BookingOverviewContact";

const useStyles = makeStyles(theme => ({
  overview: {
    display: "block",
    width: theme.components.bookingOverview.width
  },
  heading: {
    backgroundColor: "#DC1E5E",
    color: "#FFF",
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    fontWeight: "bold",
    fontSize: "22px"
  },
  date: {
  },
  time: {
    display: "flex",
    alignItems: "center"
  },
  map: {
    height: "100vh",
    width: "100%",
    position: "absolute",
    opacity: 0.5
  }
}))

const BookingOverview = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const bookingOverview = useSelector(bookingSelectors.bookingOverview());
  const showBookingOverview = useSelector(bookingSelectors.bookingOverviewShow());

  const handleDrawerOnClick = () => {
    dispatch(bookingActions.showOverview({ show: false }));
  }

  if(!bookingOverview) return null;

  return (
    <Drawer
      classes={{ paper: classes.root }}
      open={showBookingOverview}
      variant="temporary"
      anchor="left"
      onClick={handleDrawerOnClick}
    >
      <div className={classes.overview}>
        <div className={classes.heading}> Booking Overview </div>
        <BookingOverviewWhen when={bookingOverview.when} />
        <BookingOverviewWhere where={bookingOverview.where} />
        <BookingOverviewOptions options={bookingOverview.options} />
        <BookingOverviewContact contact={bookingOverview.contact} />
      </div>
    </Drawer>
    
  )
}

export default BookingOverview;