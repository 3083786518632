import React from "react";
import { makeStyles } from "@material-ui/core"
import cn from "classnames";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    margin: "0 auto",
    maxWidth: "1280px",
    padding: props => props.gutter ? theme.spacing(1) : 0
  },
}))

const Wrapper = (props) => {
  const { className, gutter, children } = props;
  const classes = useStyles({ gutter });
  
  return (
    <div className={cn({[classes.wrapper]: true}, className)}>
      {children}
    </div>
  )
}

export default Wrapper;