import React from "react";
import { makeStyles, Box, SvgIcon, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  detail: {
    background: props => props.background || "none",
    flex: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: props => props.color || theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  description: {
    minHeight: "100px"
  },
  icon: {
    color: props => props.color || theme.palette.primary.main,
    fontSize: "48px",
    marginRight: theme.spacing(0.75),
    marginBottom: theme.spacing(2),
  }
}))

const Card = (props) => {
  const { color, background, icon, title, description } = props;
  const classes = useStyles({ color, background });

  return (
    <Box boxShadow={1} className={classes.detail}>
      <SvgIcon component={icon} className={classes.icon} />
      <Typography variant="h3" className={classes.title}> {title} </Typography>
      {description && <div className={classes.description}>
        <Typography variant="body1">
          {description}
        </Typography>
      </div>}
    </Box>
  )  
}

export default Card;