import React from "react";
import { useHistory } from "react-router";
import { Settings as SettingsIcon } from "@material-ui/icons";
import BookingOverviewItem from "./BookingOverviewItem";

const BookingOverviewOptions = (props) => {
  const { options } = props;
  const history = useHistory();

  if(!options) return null;

  return (
    <BookingOverviewItem 
      heading="Photobooth Options" 
      icon={SettingsIcon}
      onClick={() => history.push("/booking/options")}
    >
      <div> {options.style} </div>
      <div> {options.bg} </div>
      <div> {options.props} </div>
      <div> {options.guestbook} </div>
    </BookingOverviewItem>
  )
}

export default BookingOverviewOptions ;