import React from "react";

const Moustache = (props) => {
  return (
    <svg viewBox="0 0 213.057 213.057" className={props.className}>
      <path d="M202.673,134.895c-9.029,10.313-23.184,15.542-42.072,15.542c-34.264,0-48.313-11.299-54.073-20.676
        c-5.759,9.376-19.809,20.676-54.073,20.676c-18.888,0-33.043-5.229-42.072-15.542c-12.59-14.381-10.252-33.166-10.146-33.958
        c0.281-2.114,2.174-3.623,4.297-3.458c2.125,0.177,3.737,1.989,3.666,4.121c-0.009,1.475,0.583,9.099,13.297,9.395l0.128,0.002
        c5.69-0.001,11.729-9.552,17.569-18.787C47.963,78.34,57.903,62.62,74.331,62.62c18.101,0,27.595,8.151,32.197,14.347
        c4.602-6.195,14.097-14.347,32.197-14.347c16.428,0,26.367,15.72,35.136,29.589c5.84,9.236,11.879,18.788,17.57,18.787l0.129-0.002
        c12.713-0.296,13.305-7.92,13.295-9.445c-0.072-2.132,1.542-3.919,3.667-4.096c2.11-0.174,4.015,1.369,4.297,3.483
        C212.925,101.729,215.263,120.514,202.673,134.895z"/>
    </svg>
  )
}

export default Moustache