import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import bookingService from "common/data/bookingService";
import { QuestionTypes } from "modules/booking/common";
import bookingOverviewMap from "../utils/bookingOverviewMap";

// Initial state
const initialState = {
  questions: [
    { type: QuestionTypes.WHEN, route: "/booking/when" },
    { type: QuestionTypes.WHERE, route: "/booking/where" },
    { type: QuestionTypes.OPTIONS, route: "/booking/options" },
    { type: QuestionTypes.CONTACT, route: "/booking/contact" },
    { type: QuestionTypes.CONFIRM, route: "/booking/confirm" }
  ],
  answers: null,
  loading: false,
  success: false,
  error: false,
  showOverview: false,
}

// Thunks
const submitBooking = createAsyncThunk('booking/submitBooking', 
  async (data, { rejectWithValue }) => {
    try {
      const response = await bookingService.book(data);
      if(!response.data || !response.data.success) return rejectWithValue("ERR")
      return response.data
    }
    catch(ex) {
      return rejectWithValue("ERROR")
    }
  }
)

// Reducers
const reducers = {};

reducers.answerQuestion = (state, action) => {
  if(!state.answers) state.answers = {};  
  state.answers[action.payload.question] = { ...action.payload.answers };
  return state;
};

reducers.showOverview = (state, action) => {
  state.showOverview = action.payload.show;
  return state;
}

reducers.reset = (state, action) => {
  return initialState;
}

reducers.closeError = (state, action) => {
  state.error = false;
  state.hasNext = true;
  return state;
}

const extraReducers = {};

extraReducers[submitBooking.pending] = (state, action) => {
  state.loading = true;
  return state;
}

extraReducers[submitBooking.fulfilled] = (state, action) => {
  state.loading = false;
  state.success = true;
  return state;
}

extraReducers[submitBooking.rejected] = (state, action) => {
  state.loading = false;
  state.error = true;
  return state;
}

// Slice
const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers,
  extraReducers,
})

// Selectors
const booking = state => state.booking;

const answers = state => state.booking.answers;
const answersByQuestion = (question) => createSelector(answers, answers => {
  if(!answers) return null;
  return answers[question];
});

const questions = state => state.booking.questions;
const questionByRoute = (route) => createSelector(questions, questions => {
  if(!questions) return null;
  return questions.find(x => x.route === route);
});

const questionNext = (question) => createSelector(questions, questions => {
  if(!questions) return null;
  const index = questions.findIndex(x => x.type === question.type);
  return questions[index + 1];
});

const bookingOverview = () => createSelector(answers, answers => {
  return bookingOverviewMap(answers);
});

const bookingOverviewShow = () => createSelector(booking, booking => {
  return booking.showOverview;
});

export const bookingSelectors = { 
  answers, 
  answersByQuestion, 
  questions, 
  questionByRoute,
  questionNext,
  bookingOverview,
  bookingOverviewShow,
};

export const bookingActions = { ...bookingSlice.actions, submitBooking }; 
export const bookingReducer = bookingSlice.reducer;