import React from "react";
import { Grid } from "@material-ui/core";
import { Section, Card } from "common/components";
import { QRCode as QRCodeIcon } from "common/icons";
import { ImportContacts as ImportContactsIcon } from "@material-ui/icons";

const OptionalExtras = (props) => {
  const { className } = props;
  return (
    <Section
      heading="Optional Extras"
      className={className}
    >
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Card 
            icon={QRCodeIcon} 
            title="QR Code scanning" 
            description="We offer the ability to have a QR code available after the photos have been taken so that your guests can instantly recieve the media on their phone"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card 
            icon={ImportContactsIcon} 
            title="Photobook" 
            description="A photobook can be provided along with stationary to allow your guests to paste their images &amp; write a special message for your event"
          />
        </Grid>
      </Grid>
    </Section>
  )  
}

export default OptionalExtras;