import React from "react";
import { Section, Wrapper, Pricing } from "common/components";
import { useHistory } from "react-router-dom";

const PerfectPackage = () => {
  const history = useHistory();

  return (
    <Wrapper>
      <Section
        id="packages"
        heading="Perfect package for you"
        blurb="Our packages are designed to reach everyones needs"
      >
        <Pricing 
          onButtonClick={() => history.push("/packages")}
          buttonText="View More"
        />
      </Section>
    </Wrapper>
  )
}

export default PerfectPackage;