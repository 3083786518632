import React from "react";
import { Grid } from "@material-ui/core";
import { Section, Card } from "common/components";
import { 
  Moustache as MoustacheIcon, 
  Unpack as UnpackIcon 
} from "common/icons";
import { 
  LocalShipping as LocalShippingIcon, 
  Mood as MoodIcon, 
  AllInclusive as AllInclusiveIcon, 
  Usb as UsbIcon
} from "@material-ui/icons";

const WhatIsIncluded = () => {
  return (
    <Section
      heading="What Is Included"
    >
      <Grid container>
        <Grid item xs={12} sm={6} md={4}>
          <Card 
            icon={MoustacheIcon} 
            title="Props" 
            description="One of the things which makes us unique is that we have a wide selection of high quality props which we provide for the event"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card 
            icon={MoodIcon} 
            title="Friendly Attendant" 
            description="There will be a friendly attendant during the entire event that ensures the operation of the booth goes as smoothly as possible"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card 
            icon={LocalShippingIcon} 
            title="Delivery" 
            description="We service most of Sydney including the CBD, Greater Western Sydney, Sutherland/Cronulla, The Hills District, Northern Beaches and the Eastern Suburbs"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card 
            icon={UnpackIcon} 
            title="Pack up &amp; Set up" 
            description="We will arrive to your event 1 hour prior to the start time and ensure everything is good to go. Packup usually takes 30 minutes and occurs after the end time"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card 
            icon={UsbIcon} 
            title="Digital Copies" 
            description="All copies of the media is saved as digital copies which are sent to you a few days after the event. We will send you a link where you can download all the media"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card 
            icon={AllInclusiveIcon} 
            title="Unlimited Media" 
            description="We happily provide unlimited prints &amp; media for all our services (within reason). So if there is a big group shot, we normally print multiple copies for everyone"
          />
        </Grid>
      </Grid>
    </Section>
  )  
}

export default WhatIsIncluded;