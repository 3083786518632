import BookingQuestions from "./features/booking-questions/BookingQuestions";
import BookingConfirmation from "./features/booking-confirmation/BookingConfirmation";

const BookingRoutes = [
  {
    path: "/booking/when",
    component: BookingQuestions
  },
  {
    path: "/booking/where",
    component: BookingQuestions
  },
  {
    path: "/booking/options",
    component: BookingQuestions
  },
  {
    path: "/booking/contact",
    component: BookingQuestions
  },
  {
    path: "/booking/confirm",
    component: BookingQuestions
  },
  {
    path: "/booking/thank-you",
    component: BookingConfirmation
  }
];

export default BookingRoutes;