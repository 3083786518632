import Landing from "./landing/Landing";
import AboutUs from "./about-us/AboutUs";
import HowItWorks from "./how-it-works/HowItWorks";
import Packages from "./packages/Packages";
import Contact from "./contact/Contact";

const ContentRoutes = [
  {
    path: "/",
    component: Landing
  },
  {
    path: "/about-us",
    component: AboutUs
  },
  {
    path: "/how-it-works",
    component: HowItWorks
  },
  {
    path: "/how-it-works/backgrounds",
    component: HowItWorks
  },
  {
    path: "/packages",
    component: Packages
  },
  {
    path: "/contact",
    component: Contact
  }
];

export default ContentRoutes;