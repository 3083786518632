import React from "react";
import { Switch, Route } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { useLoadScript } from "@react-google-maps/api";
import { ScrollToTop, BackToTop, Providers } from "./common";
import { BookingRoutes } from "./modules/booking";
import { ContentRoutes } from "./modules/content";
import { ExtraRoutes } from "./modules/extra";
import { EventRoutes } from "./modules/services";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./app.css";

const routes = [...BookingRoutes, ...ContentRoutes, ...EventRoutes, ...ExtraRoutes];

function App() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDwzzj-MfFehmiAPW2eGBGjixGqXRxRESA",
    libraries: ["places"]
  });
  
  if(!isLoaded) return null;

  return (
    <div className="app" id="app">
      <CssBaseline />
      <Providers>
        <ScrollToTop />
        <BackToTop />
        <Switch>
          {routes.map((route, idx) => <Route key={idx} path={route.path} component={route.component} exact />)}
        </Switch>
      </Providers>
    </div>
  )
}

export default App;