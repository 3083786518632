import React from "react";
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(1),
    fontSize: "38px",
    textAlign: "center"
  },
  blurb: {
    fontSize: "22px",
    textAlign: "center"
  },
}))

const SectionHeading = (props) => {
  const { heading, blurb } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2 className={classes.heading}> 
        {heading} 
      </h2>
      <div className={classes.blurb}>
        {blurb}
      </div>
    </div>
  )
}

export default SectionHeading;