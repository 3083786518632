import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { FullScreen } from "common/components";
import { backgrounds } from "common/data";

const useStyles = makeStyles((theme) => ({
  example: {
    margin: `${theme.spacing(2)}px 0`
  },
  image: {
    width: "100%",
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
  },
  item: {
    position: "relative"
  },
  title: {
    position: "absolute",
    fontWeight: "bold",
    textAlign: "center",
    bottom: 0,
    background: `rgba(255,255,255,0.9)`,
    padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
    width: "100%",
    textTransform: "capitalize"
  }
}));

const BackgroundItem = (props) => {
  const { img, title } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={4}>    
      <div className={classes.item}>
        <img alt="Forever Snaps" src={img} className={classes.image} />
        <div className={classes.title}>                  
          <span>{title}</span>
        </div>
      </div>
    </Grid>    
  )
}

const BackgroundFullScreen = (props) => {
  const classes = useStyles();
  const { open, onClose } = props;

  return (
    <>
      <FullScreen title="Backgrounds" open={open} onClose={onClose}>
        <Grid container className={classes.example}>
          {backgrounds.map((bg, i) => (
            <BackgroundItem
              key={i}
              img={bg.image}
              title={bg.title}
            />
          ))}          
        </Grid>
      </FullScreen>
    </>
  )
}

export default BackgroundFullScreen;