import { createMuiTheme } from "@material-ui/core";
import overrideTextField from "./overrideTextField";
import overrideFormControl from "./overrideFormControl";
import overrideTypography from "./overrideTypography";
import overrideLink from "./overrideLink";
import overrideButton from "./overrideButton";
import overrideAlert from "./overrideAlert";
import palette from "./palette";

const defaultTheme = createMuiTheme({
  components: {
    menu: {
      height: "64px"
    },
    footer: {
      height: "64px"
    },
    bookingOverview: {
      width: "250px"
    }
  },
});

// base defaultTheme vars
palette(defaultTheme);

overrideTextField(defaultTheme);
overrideTypography(defaultTheme);
overrideLink(defaultTheme);
overrideButton(defaultTheme);
overrideFormControl(defaultTheme);
overrideAlert(defaultTheme);

export default createMuiTheme(defaultTheme);