import React from "react";
import { useHistory } from "react-router";
import { Room as RoomIcon } from "@material-ui/icons";
import BookingOverviewItem from "./BookingOverviewItem";

const BookingOverviewWhere = (props) => {
  const { where } = props;
  const history = useHistory();

  if(!where) return null;

  return (
    <BookingOverviewItem 
      heading="Location" 
      icon={RoomIcon}
      onClick={() => history.push("/booking/where")}
    >
      <div> {where.street} </div>
      <div> {where.address} </div>
      <div> {where.country} </div>
    </BookingOverviewItem>
  )
}

export default BookingOverviewWhere;