const overrideTextField = (theme) => {
  theme.typography.body1 = {
    fontSize: "14px",
    lineHeight: "26px"
  };
  theme.typography.subtitle1 = { 
    fontSize: "1.7em",
    fontWeight: "bold"
  };
  theme.typography.h2 = {
    fontSize: "2.4em",
    marginBottom: theme.spacing(1.5),
    fontWeight: "bold"
  };
  theme.typography.h3 = {
    fontSize: "1.7em",
    marginBottom: theme.spacing(1.5),
    fontWeight: "bold"
  }
}

export default overrideTextField;