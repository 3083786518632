import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { events } from "common/data";
import cn from "classnames";

const useStyles = makeStyles((theme) => ({
  item: {
    height: 200,
    width: 200,
    background: theme.palette.grey.light,
    marginBottom: theme.spacing(1),
    border: `solid 1px ${theme.palette.grey.main}`,
    marginLeft: theme.spacing(1),
  },
  red: {
    height: 200,
    width: 200,
    background: theme.palette.primary.light,
    marginBottom: theme.spacing(1),
    border: `solid 1px ${theme.palette.primary.main}`,
    marginLeft: theme.spacing(1),
  }
}))

const useIntersectionObserver = (ref, onIntersect) => {
  const handleOnIntersect = (arr) => { 

    if(!arr || !arr.length || arr.length <= 0) return;

    onIntersect(arr[0], 123);
  }

  const observer = new IntersectionObserver(handleOnIntersect, {
    rootMargin: "0px",
    threshold: 1.0
  });

  useEffect(() => {
    if(!ref) return;
    observer.observe(ref.current);
    return () => observer.unobserve(ref.current);
  }, []);

}

const Items = (props) => {
  const classes = useStyles();
  const ref = useRef();
  const { isLast, onLoadMore } = props;

  useIntersectionObserver(ref, (e) => {
    if(!e.target.hasAttribute("data-last") || !e.isIntersecting) return;
    
    onLoadMore();
  });

  return (
    <>
      <div className={classes.item}></div>
      <div className={classes.item}></div>
      <div className={classes.item}></div>
      <div className={classes.item}></div>
      <div ref={ref} className={cn({[classes.red]: isLast})} data-last={isLast ? "true" : undefined}></div>
    </>
  )
}

const Image = () => {
  const [loading, setLoading] = useState(false);

  return 
}

const Sandbox = () => {
  const classes = useStyles();

  const [comps, setComps] = useState([]);

  useEffect(() => {                   
    setComps(c => ([...c, Items]));
  }, [])

  const renderItems = () => {
    if(!comps) return null;

    return comps.map((Comp, index) => 
      <Comp 
        key={index}
        isLast={index === (comps.length - 1)} 
        onLoadMore={handleClick}
      />
    );
  }

  const handleClick = () => {
    setComps(c => ([...c, Items]));
  }

  return (
    <div>
      <Skeleton />
    </div>
  )
}

export default Sandbox