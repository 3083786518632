import React from "react";
import { Grid } from "@material-ui/core";
import PriceItem from "./PriceItem";

const Pricing = (props) => {
  const { onButtonClick, buttonText } = props;

  return (
    <Grid container>
      <PriceItem 
        title="Basic"
        description="3 hour hire"
        price="$700"
        includes={["Wide variety of props", "Setup & packup", "Unlimited media", "Friendly attendant", "Digital copies media"]}
        buttonText={buttonText}
        onButtonClick={onButtonClick}
      />
      <PriceItem 
        title="Standard"
        description="4 hour hire"
        price="$850"
        includes={["Wide variety of props", "Setup & packup", "Unlimited media", "Friendly attendant", "Digital copies media", "Photobook & stationary"]}
        selected={true}
        buttonText={buttonText}
        onButtonClick={onButtonClick}
      />
      <PriceItem  
        title="Deluxe"
        description="5 hour hire"
        price="$1000"
        includes={["Wide variety of props", "Setup & packup", "Unlimited media", "Friendly attendant", "Digital copies media", "Photobook & stationary", "QR code scanning"]}
        buttonText={buttonText}
        onButtonClick={onButtonClick}
      />   
    </Grid>      
  )
}

export default Pricing;