import Wip from "./wip/Wip";
import Sandbox from "./sandbox/Sandbox";

const ExtraRoutes = [
  {
    path: "/wip",
    component: Wip
  },
  {
    path: "/sandbox",
    component: Sandbox
  }
];

export default ExtraRoutes;