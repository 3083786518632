import React from "react";
import { makeStyles } from "@material-ui/core";
import logo from "assets/logo-white.png";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: props => props.size ? `${props.size}px` : "100%",
  },
}))

const LogoFull = (props) => {
  const { size } = props;
  const classes = useStyles({ size });

  return  (
    <img alt="Forever Snaps" src={logo} className={classes.logo} />
  )
}

export default LogoFull