import axios from "axios";

const bookingService = {};

const base = {
  "development": "http://localhost:12345",
  "production": "https://api.foreversnaps.com.au" 
}

bookingService.book = async (payload) => {
  const baseUrl = base[process.env.NODE_ENV];
  const resource = "booking"
  return axios.post(`${baseUrl}/${resource}`, payload);
}

export default bookingService;