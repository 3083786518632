import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { configureStore } from "@reduxjs/toolkit";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import DayJSUtils from "@date-io/dayjs";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import defaultTheme from "./theme/defaultTheme";

import { bookingReducer } from "modules/booking/common/slice/BookingSlice";

const configureAppStore = () => {
  return configureStore({
    reducer: {
      booking: bookingReducer
    },
  })
}

const store = configureAppStore();

const Providers = (props) => {
  const { children } = props;
  return (
    <Provider store={store}>
      <BrowserRouter>
        <GoogleReCaptchaProvider reCaptchaKey="6Leu6MgaAAAAAHcObHvcPV4VswobF5bSfs2X2S01">
          <ThemeProvider theme={defaultTheme}>
            <MuiPickersUtilsProvider utils={DayJSUtils}>
              {children}
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </GoogleReCaptchaProvider>
      </BrowserRouter>
    </Provider>
  )
}

export default Providers;