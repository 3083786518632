import React from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import { CalendarToday as CalendarTodayIcon, ArrowRightAlt as ArrowRightAltIcon } from "@material-ui/icons";
import BookingOverviewItem from "./BookingOverviewItem";

const useStyles = makeStyles(theme => ({
  time: {
    display: "flex",
    alignItems: "center"
  }
}))

const BookingOverviewWhen = (props) => {
  const { when } = props;
  const classes = useStyles();
  const history = useHistory();

  if(!when) return null;

  return (
    <BookingOverviewItem 
      heading="Date &amp; Time" 
      icon={CalendarTodayIcon}
      onClick={() => history.push("/booking/when")}
    >
      <div className={classes.date}>
        {when.date}
      </div>
      <div className={classes.time}>
        <div>{when.start}</div>
        <ArrowRightAltIcon />
        <div>{when.end}</div>
      </div>
    </BookingOverviewItem>
  )
}

export default BookingOverviewWhen;