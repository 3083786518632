const overrideButton = (theme) => {
  theme.overrides["MuiAlert"] = {
    root: {
      alignItems: "center"
    },
    standardSuccess: {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.white.main,
      "& .MuiAlert-icon": {
        color: theme.palette.white.main
      }
    },
    standardError: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.white.main,
      "& .MuiAlert-icon": {
        color: theme.palette.white.main
      }
    },
    action: {
      color: theme.palette.white.main
    }
  }
}

export default overrideButton;