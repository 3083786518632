import React from "react";
import { makeStyles, Button, CircularProgress } from "@material-ui/core";
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  action: {
    marginTop: theme.spacing(2),
    display: "flex",
    flex: 1,
    justifyContent: "flex-end"
  },
  loader: {
    color: theme.palette.white.main
  }
}))

const ActionButtons = ({
  primaryText, 
  secondaryText, 
  hideSecondary,
  onPrimaryClick, 
  onSecondaryClick,
  loading
}) => {
  const classes = useStyles();

  return (
    <div className={classes.action}>
      {!hideSecondary && 
        <Button 
          color="primary" 
          disableElevation
          disableFocusRipple
          disableRipple
          disabled={loading}
          onClick={onSecondaryClick}
        > 
          {secondaryText}
        </Button>
      }     
      {primaryText && 
        <Button 
          variant="contained" 
          color="primary" 
          disabled={loading}
          endIcon={loading ? <CircularProgress size={20} className={classes.loader} /> : <ArrowForwardIcon />}
          onClick={onPrimaryClick}
        > 
          {primaryText} 
        </Button>  
      }   
    </div>
  )
}

export default ActionButtons;
