import React from "react";

const Unpack = (props) => {
  return (
    <svg viewBox="0 0 512.004 512.004" className={props.className}>
      <g>
        <g>
          <path d="M509.483,262.693l-55.454-81.68l55.454-81.68c2.592-3.896,3.222-8.759,1.713-13.183c-1.523-4.424-5.01-7.896-9.448-9.36
            l-181-60c-6.475-2.183-13.477,0.278-17.227,5.903l-47.52,71.279l-47.52-71.279c-3.779-5.654-10.84-8.101-17.227-5.903l-181,60
            c-4.438,1.465-7.925,4.937-9.448,9.36c-1.509,4.424-0.879,9.287,1.714,13.184l55.454,81.68L2.521,262.693
            c-2.593,3.896-3.223,8.76-1.714,13.184c1.523,4.424,5.01,7.896,9.448,9.36l181,60c6.37,2.142,13.456-0.249,17.227-5.903
            l47.52-71.279l47.52,71.279c3.774,5.66,10.864,8.044,17.227,5.903l181-60c4.438-1.465,7.925-4.937,9.448-9.36
            C512.706,271.453,512.076,266.59,509.483,262.693z M256.002,225.208l-132.583-44.194l132.583-44.194l132.583,44.194
            L256.002,225.208z"/>
        </g>
        <g>
          <path d="M330.24,373.699c-18.88,6.334-40.226-0.535-51.68-17.725l-22.557-33.836l-22.56,33.836
            c-11.431,17.157-32.752,24.089-51.753,17.695L61.002,333.443v87.57c0,6.46,4.131,12.188,10.254,14.224l179.912,59.971
            c0.542,0.19,1.084,0.337,1.626,0.454c0,0,0.015,0,0.015,0.015h0.015c1.055,0.22,2.124,0.337,3.179,0.337
            c1.128,0,2.241-0.132,3.354-0.381c0.498-0.117,0.981-0.249,1.465-0.41l179.927-59.985c6.122-2.037,10.253-7.765,10.253-14.225
            v-87.568L330.24,373.699z"/>
        </g>
      </g>
    </svg>
  )
}

export default Unpack