import React from "react";
import MenuItem from "./MenuItem";

const DesktopMenuItems = (props) => {
  const { items } = props;

  return items.map((i, index) => 
    <MenuItem 
      key={index}
      title={i.title} 
      selected={i.selected} 
      to={i.to}
    />)
}

export default DesktopMenuItems;