import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Button } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: theme.palette.primary.main,
    flexDirection: "column"
  },
  heading: {
    fontSize: "64px",
    margin: theme.spacing(1),
    color: theme.palette.primary.main
  },
  container: {
    background: theme.palette.white.main,
    padding: `${theme.spacing(1)}px ${theme.spacing(6)}px`,
    marginBottom: theme.spacing(1),
  },
  button: {
    fontSize: "16px",
    color: theme.palette.white.main
  }
}))

const Wip = () => {
  const classes = useStyles();
  const history = useHistory();

  const onBackClick = () => { 
    history.goBack();
  }

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.container}>
          <h1 className={classes.heading}> Coming Soon </h1>
        </div>
        <Button 
          startIcon={<ArrowBackIcon />} 
          className={classes.button}
          onClick={onBackClick}
        >
          Go Back
        </Button>
      </div>
    </div>
  )
}

export default Wip