import React from "react";
import { makeStyles, SvgIcon } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  detail: {
    color: theme.palette.white.main,
    background: props => props.background || theme.palette.primary.main,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  info: {
    marginLeft: theme.spacing(1.5)
  },
  label: {
    fontWeight: "bold"
  },
  value: {
    fontSize: "24px",
    fontWeight: "bold"
  }
}))

const Detail = (props) => {
  const { background, heading, value, icon } = props;
  const classes = useStyles({ background });

  return (
    <div className={classes.detail}>
      <div className={classes.icon}>
        <SvgIcon component={icon} fontSize="large" />
      </div>
      <div className={classes.info}>
        <div className={classes.label}> {heading} </div>
        <div className={classes.value}> {value} </div>
      </div>
    </div> 
  )
}

export default Detail