import React, { useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { Autocomplete} from "@react-google-maps/api";
import "./PlaceAutocomplete.scss";

const useStyles = makeStyles(theme => ({
  input: {
    "& .MuiInputBase-root": {
      background: "#fff",
    }
  },
}));

const PlaceAutocomplete = (props) => {
  const classes = useStyles();
  const { onPlaceChange, onTextChange, value, name, error } = props;
  const [ autocomplete, setAutocomplete ] = useState(null);
  
  const mapType = (types) => {
    const kvp = {
      "street_number": {
        "key": "streetNumber",
        "prop": "long_name",
      },
      "route": {
        "key": "street",
        "prop": "long_name",
      },
      "locality": {
        "key": "city",
        "prop": "long_name",
      },
      "administrative_area_level_1": {
        "key": "state",
        "prop": "short_name",
      },
      "country": {
        "key": "country",
        "prop": "long_name",
      },
      "postal_code": {
        "key": "postcode",
        "prop": "short_name",
      },
    }
    const type = types.find(t => kvp[t]);
    return type ? kvp[type] : undefined;
  }

  const mapPlace = (place) => {
    const location = {
      "search": undefined,
      "streetNumber": undefined,
      "street": undefined,
      "city": undefined,
      "state": undefined,
      "country": undefined,
      "postcode": undefined,
      "longitude": undefined,
      "latitude": undefined,
    };

    if (!place || !place.address_components) return location;

    place.address_components.forEach(x => {
      const type = mapType(x.types);
      if (!type) return;
      location[type.key] = x[type.prop];
    });

    const street = [location.streetNumber, location.street].filter(Boolean).join(" ");
    const address = [location.city, location.state, location.postcode].filter(Boolean).join(" ");
    const country = location.country;
    
    location.search = [street, address, country].filter(Boolean).join(", ");
    location.longitude = place.geometry.location.lng();
    location.latitude = place.geometry.location.lat();

    return location;
  }

  const handleLoad = (ac) => {
    setAutocomplete(ac)
  }

  const handlePlaceChanged = () => {
    if (!autocomplete) return;
    const place = autocomplete.getPlace();
    if (!place || !place.geometry) return;
    onPlaceChange(mapPlace(place));
  }

  return (
    <>
      <Autocomplete
        bounds={{ west: 150.53939, south: -34.17597, east: 151.38231, north: -33.52865 }}
        options={{ strictBounds: true }}
        fields={["address_component", "geometry", "utc_offset_minutes"]}
        onLoad={handleLoad}
        onPlaceChanged={handlePlaceChanged}
      >
        <TextField
          type="text"
          fullWidth
          name={name}
          value={value}
          onChange={onTextChange}
          className={classes.input}
          error={Boolean(error)}
          helperText={error}
          variant="outlined"
        />
      </Autocomplete>
    </>
  )
}

export default PlaceAutocomplete;