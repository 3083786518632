import React from "react";
import { useHistory } from "react-router";
import { Person as PersonIcon } from "@material-ui/icons";
import BookingOverviewItem from "./BookingOverviewItem";

const BookingOverviewContact = (props) => {
  const { contact } = props;
  const history = useHistory();

  if(!contact) return null;

  return (
    <BookingOverviewItem 
      heading="Contact Information" 
      icon={PersonIcon}
      onClick={() => history.push("/booking/contact")}
    >
      <div> {contact.name} </div>
      <div> {contact.number} </div>
      <div> {contact.email} </div>
    </BookingOverviewItem> 
  )
}

export default BookingOverviewContact;