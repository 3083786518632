import React from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles, Typography, Button } from "@material-ui/core";
import { ContentItem } from "common/components";
import intro from "assets/intro.png";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
    background: theme.palette.blueGrey.light,
  },
  link: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  button: {
    marginTop: theme.spacing(2)
  }
}))

const Intro = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <ContentItem
        image={intro}
        opposite={true}
      >
        <Typography variant="h2"> Let us introduce ourselves </Typography>
        <Typography paragraph variant="body1"> 
          Welcome to Forever Snaps. We are a Photobooth company which aims to create and capture a memorable experience for you and your guests 
        </Typography>
        <Typography paragraph variant="body1"> 
          We are based in Sydney and take pride in the the unique and fun experience that we provide. <Link to="/contact" className={classes.link}>Have a chat with us</Link> and book your next photobooth experience with us  
        </Typography>
        <Button variant="contained" color="primary" onClick={() => history.push("/about-us")}> Learn more </Button>
      </ContentItem>
    </div>
  )  
}

export default Intro;
