import React from "react";
import { makeStyles } from "@material-ui/core";
import { Wrapper } from "common/components";
import cn from "classnames";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: props => props.opposite ? "row-reverse" : "row",
      textAlign: "left"
    }
  },
  text: {
    flex: 2,
    padding: theme.spacing(2)
  },
  img: {
    padding: `0 ${theme.spacing(2)}px`,
    flex: 1,
    flexBasis: "150px",
    flexGrow: 1,
    "& img": {
      position: "relative",
      top: "6px",
      width: "100%"
    }
  },
}))

const ContentItem = (props) => {
  const { opposite, image, children, className } = props;
  const classes =  useStyles({ opposite });
  
  return (
    <Wrapper className={cn({[classes.container]: true}, className)}>
      <div className={classes.text}>
        {children}
      </div>
      <div className={classes.img}>
        <img alt="Forever Snaps" src={image} />
      </div>
    </Wrapper>
  )
}



export default ContentItem