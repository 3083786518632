import React from "react";

const Party = (props) => {
  return (
    <svg viewBox="0 0 306.976 306.976" className={props.className}>
      <g>
        <path d="M230.879,144.952c-3.426-4.771-4.874-10.757-3.84-16.786l2.061-12.018l-8.73-8.51c-0.133-0.129-0.253-0.267-0.382-0.399
          c-66.809,55.733-74.408,145.071-73.68,188.929c0.027,1.598,1.254,2.919,2.846,3.067c1.591,0.148,3.039-0.925,3.361-2.491
          C170.944,206.99,205.797,164.569,230.879,144.952z"/>
        <path d="M99.861,85.018L79.7,89.531l-7.712,19.167c-0.14,0.348-0.298,0.685-0.454,1.023c31.466,44.515,47.854,135.4,54.405,181.181
          c0.154,1.074,1.111,1.846,2.193,1.773c1.082-0.073,1.925-0.968,1.934-2.052c0.797-98.746-14.309-164.631-29.555-205.773
          C100.294,84.904,100.081,84.969,99.861,85.018z"/>
        <path d="M237.742,244.904l-2.857-3.93c-2.312-3.18-3.69-6.801-4.133-10.52c-21.992,5.865-55.074,20.873-65.644,58.833
          c-0.571,2.05,0.423,4.213,2.35,5.115c1.928,0.902,4.226,0.28,5.434-1.472c11.534-16.727,32.267-38.816,63.187-43.301
          c0.017-0.048,0.029-0.097,0.046-0.146L237.742,244.904z"/>
        <path d="M80.479,206.57c-2.011,2.813-4.686,5.177-7.904,6.851l-4.309,2.241l-0.597,4.82c-0.18,1.452-0.504,2.857-0.943,4.21
          c18.465,20.857,32.072,49.507,40.295,70.078c0.551,1.379,2.056,2.118,3.486,1.716c1.429-0.403,2.326-1.818,2.077-3.283
          C105.303,250.21,92.568,223.285,80.479,206.57z"/>
        <path d="M250.59,109.166l-3.839,22.381c-0.153,0.89,0.213,1.79,0.944,2.321c0.413,0.3,0.903,0.453,1.395,0.453
          c0.378,0,0.757-0.09,1.104-0.273l20.1-10.567l20.099,10.567c0.347,0.183,0.726,0.273,1.104,0.273c0.492,0,0.982-0.153,1.395-0.453
          c0.731-0.531,1.097-1.431,0.944-2.321l-3.839-22.381l16.261-15.851c0.647-0.631,0.88-1.574,0.601-2.433
          c-0.279-0.859-1.022-1.485-1.916-1.615l-22.472-3.265l-10.05-20.363c-0.4-0.81-1.225-1.323-2.128-1.323
          c-0.903,0-1.728,0.513-2.128,1.323l-10.05,20.363l-22.472,3.265c-0.894,0.13-1.637,0.756-1.916,1.615
          c-0.279,0.859-0.046,1.802,0.6,2.433L250.59,109.166z"/>
        <path d="M95.493,65.501c0.882-0.197,1.575-0.878,1.788-1.756c0.213-0.878-0.09-1.8-0.783-2.38L72.588,41.36l2.925-31.037
          c0.085-0.899-0.349-1.769-1.117-2.243c-0.382-0.235-0.814-0.353-1.245-0.353c-0.438,0-0.875,0.121-1.26,0.362L45.476,24.647
          L16.861,12.275c-0.303-0.131-0.623-0.195-0.941-0.195c-0.553,0-1.1,0.193-1.537,0.564c-0.689,0.585-0.985,1.51-0.765,2.386
          l7.585,30.238L0.593,68.658c-0.597,0.678-0.758,1.636-0.414,2.471c0.344,0.836,1.132,1.404,2.033,1.465l31.102,2.131l15.877,26.829
          c0.429,0.725,1.208,1.165,2.042,1.165c0.06,0,0.12-0.002,0.181-0.007c0.901-0.068,1.684-0.642,2.022-1.48l11.637-28.921
          L95.493,65.501z"/>
        <path d="M302.251,235.51c-0.13-0.894-0.757-1.636-1.616-1.915l-14.62-4.745l-4.379-14.734c-0.257-0.866-0.984-1.511-1.874-1.663
          c-0.134-0.023-0.268-0.034-0.401-0.034c-0.752,0-1.469,0.358-1.92,0.979l-9.031,12.439l-15.366-0.388
          c-0.02,0-0.04-0.001-0.06-0.001c-0.88,0-1.69,0.488-2.1,1.269c-0.42,0.8-0.35,1.769,0.181,2.499l9.039,12.433l-5.118,14.495
          c-0.301,0.852-0.092,1.8,0.539,2.447c0.454,0.465,1.069,0.716,1.699,0.716c0.245,0,0.493-0.038,0.734-0.116l14.618-4.755
          l12.204,9.346c0.422,0.323,0.931,0.489,1.443,0.489c0.358,0,0.717-0.081,1.051-0.245c0.81-0.4,1.323-1.225,1.322-2.129
          l-0.005-15.371l12.66-8.718C301.993,237.295,302.381,236.404,302.251,235.51z"/>
        <path d="M63.347,195.678c0.801-0.417,1.297-1.252,1.278-2.156c-0.019-0.903-0.549-1.717-1.368-2.1l-13.925-6.51l-2.531-15.161
          c-0.149-0.891-0.79-1.62-1.655-1.881c-0.226-0.068-0.457-0.101-0.685-0.101c-0.647,0-1.279,0.265-1.734,0.753l-10.494,11.232
          l-15.202-2.278c-0.118-0.018-0.235-0.026-0.352-0.026c-0.77,0-1.501,0.375-1.948,1.019c-0.516,0.742-0.565,1.712-0.128,2.503
          l7.439,13.452l-6.864,13.754c-0.403,0.808-0.313,1.775,0.233,2.495c0.454,0.598,1.156,0.938,1.89,0.938
          c0.15,0,0.301-0.014,0.451-0.043l15.092-2.918l10.96,10.778c0.451,0.444,1.052,0.681,1.665,0.681c0.262,0,0.525-0.043,0.78-0.132
          c0.853-0.297,1.463-1.053,1.574-1.949l1.889-15.255L63.347,195.678z"/>
        <path d="M164.224,67.025l-1.845-4.493l-4.748-1.027c-3.545-0.767-6.747-2.343-9.427-4.526c-7.651,20.35-13.008,51.827-7.374,99.172
          c0.303,2.548,4.02,2.518,4.262-0.036c2.083-21.962,7.451-57.474,21.156-85.27C165.465,69.654,164.781,68.381,164.224,67.025z"/>
        <path d="M170.993,12.588l1.552,15.293l-11.718,9.948c-0.689,0.585-0.986,1.509-0.766,2.386c0.219,0.876,0.917,1.552,1.8,1.743
          l15.024,3.25l5.84,14.219c0.343,0.836,1.131,1.404,2.032,1.466c0.055,0.004,0.109,0.006,0.163,0.006
          c0.84,0,1.624-0.446,2.051-1.179l7.734-13.284l15.328-1.16c0.901-0.068,1.685-0.642,2.022-1.48c0.337-0.838,0.17-1.795-0.432-2.468
          l-10.244-11.46l3.633-14.936c0.214-0.878-0.09-1.801-0.783-2.381c-0.435-0.364-0.977-0.554-1.524-0.554
          c-0.324,0-0.65,0.066-0.957,0.202l-14.065,6.201L174.6,10.329c-0.382-0.236-0.814-0.353-1.246-0.353
          c-0.437,0-0.875,0.121-1.26,0.362C171.329,10.817,170.902,11.689,170.993,12.588z"/>
      </g>
    </svg>
  )
}

export default Party