import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles, useTheme, Link, Typography } from "@material-ui/core";
import qs from "query-string";
import { Wrapper } from "common/components";
import Step from "./Step";
import BackgroundFullScreen from "./BackgroundFullScreen";

const useStyles = makeStyles((theme) => ({
  link: {
    fontWeight: "bold",
    color: theme.palette.white.main,
  },
  icon: {
    marginLeft: theme.spacing(0.5)
  }
}));

const PlanningTheEvent = (props) => {
  const { className } = props;
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const [ open, setOpen ] = useState(false);

  useEffect(() => {
    setOpen(Boolean(qs.parse(location.search).bg));
  }, [location.search]);
  
  return (
    <>
      <Wrapper className={className}>
        <Step 
          number="01"
          background={theme.palette.primary.main}
          title="Select a package"
        >
          <Typography paragraph>
            Before we get started, you will need to <Link to="/packages" className={classes.link}>select one of our packages</Link> that best suit your needs. <Link to="/packages" className={classes.link}>You can view all our different packages here</Link>
          </Typography>
          <Typography>
            We also can customise a package for your event. <Link to="/contact" className={classes.link}>Tell us a little bit about your event</Link> such as theme, duration, type and we can discuss some different options to build a package for you 
          </Typography>    
        </Step>

        <Step 
          number="02"
          background={theme.palette.blue.main}
          title="Create a template"
        >
          <Typography paragraph>
            Here we ask for your ideas &amp; inspirations for the photo template so we can customise a design for you.
          </Typography>
          <Typography paragraph>
            The photo template comes in a standard 6x4" (15x10cm) size with a glossy or matte finish. The photo template can be orientated in either portrait or landscape style. We also offer for the photo template to be cut into 
            6x2" (15x5cm) strips allowing there to be 2 copies of the print. 
          </Typography>
          <Typography paragraph> 
            We offer up to 4 shots per print which can be placed anywhere within the photo template. These sizes can vary, however we recommend that the photos are kept at a 5:3 or 4:3 aspect ratio.
          </Typography>
        </Step>

        <Step 
          number="03"
          background={theme.palette.green.main}
          title="Choose a backdrop"
        >
          <Typography paragraph>
            A complimentary backdrop is included when you book an event. All our backdrops are stretched to remove any creases
            and make it seamless with the photo template.
          </Typography>
          <Typography paragraph>
            <Link 
              className={classes.link}
              onClick={() => setOpen(true)}
            >
              We have a wide variety of backdrops that you can choose from that you can view here
            </Link>
          </Typography> 
        </Step>

        <Step 
          number="04"
          background={theme.palette.yellow.main}
          title="Confirmation"
        >
          <Typography paragraph>
            We're almost there! We will confirm all the details of your photobooth package, photo template and backdrop choice. If you're happy with all of that,
            then just say the word and we will lock everything in. 
          </Typography>          
        </Step>

        <Step 
          number="05"
          background={theme.palette.primary.main}
          title="Party time"
        >
          <Typography paragraph>
            Sit back, party and let us do the work. We will arrive 1hour prior to your event time (or any bump in time specified) and set up the photobooth
          </Typography>
          <Typography paragraph>
            Once the event is finished our pack up process usually takes about 30minutes. We send all digital copies of all media to you within a few days.
          </Typography>      
        </Step>
      </Wrapper>

      <BackgroundFullScreen open={open} onClose={() => setOpen(false)} />
    </>
  )
}

export default PlanningTheEvent;