import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { ContentItem } from "common/components";
import cn from "classnames";
import contact from "assets/contact-us.png";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: props => props.marginTop ? theme.spacing(props.marginTop) : 0,
    paddingTop: theme.spacing(4),
    background: theme.palette.blueGrey.light,
  },
  button: {
    marginTop: theme.spacing(2)
  }
}))

const GotAQuestion = (props) => {
  const { className, marginTop } = props;
  const history = useHistory();
  const classes = useStyles({ marginTop: Number(marginTop) });

  return (
    <div className={cn({[classes.root]: true}, className)}>
      <ContentItem
        image={contact}
      >
        <Typography variant="h2"> Got a question? </Typography>
        <Typography variant="body1"> Get in contact with one of our friendly staff and we will help plan your next photobooth! </Typography>
        <Button variant="contained" color="primary" className={classes.button} onClick={() => history.push("/contact")}> CONTACT US </Button>
      </ContentItem>
    </div>
  )  
}

export default GotAQuestion;