import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Mood as CheckIcon } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { Menu, Footer } from "common/components";
import { bookingActions } from "modules/booking/common";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    height: "100vh"
  },
  confirmation: {
    height: "calc(100vh - 66px)",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    color: theme.palette.white.main,
    padding: theme.spacing(4),
  },
  icon: { 
    fontSize: "96px"
  },
  heading: {
    fontSize: "36px"
  },
  message: {
    fontSize: "24px"
  }
}));

const BookingConfirmation = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(bookingActions.reset());
  }, []);

  return (
    <div className={classes.root}>  
      <Menu />
      <div className={classes.confirmation}>
        <div><CheckIcon className={classes.icon} /></div>
        <h1 className={classes.heading}> Thank you for your booking enquiry </h1>
        <div className={classes.message}> One of our team members will get in contact with you soon </div> 
      </div>
      <Footer />
    </div>
  )
}

export default BookingConfirmation;