import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Menu, HeadTags, HalfSplash, Footer, Wrapper, Section, EventGrid, MakeABooking } from "common/components";
import { events } from "common/data";
import splash from "assets/events-splash.jpg";

const useStyles = makeStyles(theme => ({
  content: {
    textAlign: "left",
    alignItems: "flex-start"
  },
  subHeading: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1)
  }
}))

const Events = () => {
  const classes =  useStyles();
  const history = useHistory();

  const handleEventClick = (event) => {
    history.push(`/event-detail/${event.key}`);
  }

  return (
    <div className={classes.root}>
      <HeadTags 
        title="Events | Forever Snaps"
        url="https://foreversnaps.com.au/events"
        description="Making your next event a memorable and unforgettable experience"
      />
      <Menu />
      <HalfSplash 
        background={splash}
        blurb="Events"
        tag="Making your next event a memorable and unforgettable experience"
      />
      <Wrapper>
        <Section
          heading="Showcase"
          blurb="A collection of the events we have done throughout the years"
        >
          <EventGrid 
            events={events}
            onClick={handleEventClick}
          />
        </Section>
      </Wrapper>
      <MakeABooking marginTop="4" />
      <Footer />
    </div>
  )
}

export default Events