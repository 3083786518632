import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { ContentItem } from "common/components";
import cn from "classnames";
import book from "assets/book.png";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: props => props.marginTop ? theme.spacing(props.marginTop) : 0,
    paddingTop: theme.spacing(4),
    background: theme.palette.blueGrey.light,
  },
  button: {
    marginTop: theme.spacing(2)
  }
}))

const MakeABooking = (props) => {
  const { className, marginTop } = props;
  const history = useHistory();
  const classes = useStyles({ marginTop: Number(marginTop) });

  return (
    <div className={cn({[classes.root]: true}, className)}>
      <ContentItem
        image={book}
      >
        <Typography variant="h2"> Ready to get started? </Typography>
        <Typography variant="body1"> Book a photobooth with us today by completing our online booking form </Typography>
        <Button variant="contained" color="primary" className={classes.button} onClick={() => history.push("/booking/when")}> BOOK NOW </Button>
      </ContentItem>
    </div>
  )  
}

export default MakeABooking;