
const images = require.context("../../assets/events", true, /\.jpg$/);

const imagesFactory = (events) => {
  return events.map(e => ({ ...e, ...buildImages(e.key)}));
}

const buildImages = (key) => {
  // find all images for event
  let eventImages = images.keys().filter(x => x.includes(key));

  let splash;
  let postcard;
  let photos = [];

  eventImages.forEach(img => {
    if(!img.includes(".jpg")) return;

    // check if the image is a splash
    if(img.includes("splash")) {
      splash = images(img);
      return;
    }

    // check if the image is a postcard
    if(img.includes("postcard")) {
      postcard = images(img);
      return;
    }

    // check if the image is part of photos
    if(img.includes("photos")) {
      photos.push(images(img));
      return;
    }
  })

  return {
    splash,
    postcard,
    photos
  }
}

export default imagesFactory;