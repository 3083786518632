import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles, useTheme, useMediaQuery, Grid, Typography } from "@material-ui/core";
import { 
  Room as RoomIcon, 
  AccessTime as AccessTimeIcon, 
  CalendarToday as CalendarTodayIcon, 
  Star as StarIcon
} from "@material-ui/icons";
import { Menu, HalfSplash, Footer, Wrapper } from "common/components";
import { events } from "common/data";
import Detail from "./Detail";
import Gallery from "./Gallery";

const useStyles = makeStyles(theme => ({
  heading: {
    padding: `${theme.spacing(1.5)}px 0`,
    marginBottom: theme.spacing(1)
  },
  disclaimer: {
    fontSize: "12px",
    fontStyle: "italic",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
    textAlign: "center",
    color: theme.palette.grey.dark
  },
  col: {
    padding: theme.spacing(2)
  }
}))

const EventDetail = () => {
  const classes = useStyles();
  const { name } = useParams();
  const event = events.find(x => x.key === name);
  const theme = useTheme();
  const direction = useMediaQuery(theme.breakpoints.up("md")) ? "row" : "column-reverse";

  return (
    <div className={classes.root}>
      <Menu />
      <HalfSplash 
        background={event.splash}
        blurb={event.title}
        tag={event.tag}
      />
      <Wrapper gutter={true}>
        <Grid container direction={direction}>
          <Grid item xs={12} md={8} className={classes.col}>
            <Gallery images={event.photos} />
          </Grid>          
          <Grid item xs={12} md={4} className={classes.col}>
            <Typography variant="h2" className={classes.heading}> Event details </Typography>
            <Detail 
              icon={RoomIcon}
              value={event.location}
              heading="Location"
            />
            <Detail 
              icon={CalendarTodayIcon}
              value={event.date}
              heading="Date"
            />
            <Detail 
              icon={AccessTimeIcon}
              value={event.duration}
              heading="Duration"
            />
            <Detail 
              icon={StarIcon}
              value={event.occasion}
              heading="Occasion"
            />
          </Grid>
        </Grid>
        <div className={classes.disclaimer}> 
          Photos have been optimised for the web, if you are the owner of these photos or was at this event please contact us 
          for copies in full resolution.
        </div>        
      </Wrapper>
      <Footer />
    </div>
  )
}

export default EventDetail