import React from "react";
import { makeStyles, Grid, Button, Box } from "@material-ui/core"
import cn from "classnames";

const useStyles = makeStyles((theme) => ({
  item: {
    position: "relative",
    overflow: "hidden",
    color: theme.palette.black.main,
    background: theme.palette.white.main,
    margin: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      top: "40px",
    }
  },
  heading: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    textTransform: "uppercase",
    textAlign: "center",
    background: theme.palette.blueGrey.light,  
    fontWeight: "bold"
  },
  title: {
    fontSize: "24px",
  },
  description: {
    fontSize: "14px",
  },
  price: {
    color: theme.palette.primary.main,
    fontSize: "32px",
    textAlign: "center",
    marginTop: theme.spacing(1.5)
  },
  row: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(1.5),
    "&:last-child": {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  btn: {
    borderRadius: 0,
    padding: `${theme.spacing(1)}px 0`,
    width: "100%"
  },
  inclusions: {
    height: "250px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },

  recommended: {
    background: theme.palette.primary.main,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.white.main,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  selected: {
    border: `solid 2px ${theme.palette.primary.main}`,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    [theme.breakpoints.up("md")]: {
      top: "0",
    }
  }
}))

const PriceItem = (props) => {
  const { selected, title, description, price, includes, onButtonClick, buttonText } = props;
  const classes = useStyles();
  
  return (
    <Grid item xs={12} md={4}>
      <Box className={cn({[classes.item]: true, [classes.selected]: Boolean(selected) })}>
        {selected && <div className={classes.recommended}> Recommended </div>}
        <div className={classes.heading}>
          <div className={classes.title}> {title} </div>
          <div className={classes.description}> {description} </div>
          <div className={classes.price}> {price} </div>
        </div>
        <div className={classes.inclusions}>
          {includes && includes.map((x, i) => <div key={i} className={classes.row}> {x} </div>)}
        </div>
        {(buttonText && onButtonClick) && 
          <div className={classes.row}> 
            <Button 
              variant="contained" 
              className={classes.btn} 
              color={"primary"}
              onClick={onButtonClick}
            > 
              {buttonText}
            </Button>
          </div>
        }
      </Box>
    </Grid>   
  )
}

export default PriceItem;