import React from "react";
import { makeStyles } from "@material-ui/core";
import { Menu, HeadTags, HalfSplash, Footer, MakeABooking } from "common/components";
import splash from "assets/how-it-works-splash.jpg";
import PlanningTheEvent from "./PlanningTheEvent";

const useStyles = makeStyles(theme => ({
  space: {
    marginTop: theme.spacing(4)
  }
}))

const HowItWorks = () => {
  const classes = useStyles();

  return (
    <div>
      <HeadTags 
        title="How It Works | Forever Snaps"
        url="https://foreversnaps.com.au/how-it-works"
        description="We strive to keep our processes as simple as possible"
      />
      <Menu />
      <HalfSplash 
        background={splash}
        blurb="How It Works"
        tag="We strive to keep our processes as simple as possible"
      />
      <PlanningTheEvent className={classes.space} />
      <MakeABooking marginTop="4" />
      <Footer />
    </div>
  )  
}

export default HowItWorks;