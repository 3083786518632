import React, { useState } from "react";
import { makeStyles, TextField, Grid, IconButton, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Close as CloseIcon } from "@material-ui/icons";
import { Section, Wrapper, ActionButtons, Dropdown, ValidationError } from "common/components";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useFormik } from "formik";
import cn from "classnames";
import ContactFormValidation from "./ContactFormValidation";
import emailService from "common/data/emailService";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%"
  },
  row: {
    marginBottom: theme.spacing(1)
  },
  nameRow: {
    paddingRight: theme.spacing(1)
  },
}))

const ContactForm = () => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ showSnackBar, setShowSnackBar ] = useState(false);
  const [ snackbarStyle, setSnackbarStyle ] = useState();
  const [ snackbarMessage, setSnackbarMessage ] = useState();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contact: "",
      enquiry: "",
      message: ""
    },
    onSubmit: (values) => {
      handleOnSubmit(values);
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ContactFormValidation
  });

  const handleOnSubmit = async (values) => {
    setShowSnackBar(false);
    setLoading(true);

    const token = await executeRecaptcha("form");
    const resp = await emailService.send(values.name, values.email, values.contact, values.enquiry, values.message, token);

    if(!resp || !resp.data || !resp.data.success) {
      setSnackbarMessage("Uh oh, something went wrong and your message was not sent. Please give us a call")
      setSnackbarStyle("error");
    }
    else {
      setSnackbarMessage("Thank you for your message. We will be in touch shortly.")
      setSnackbarStyle("success");  
      formik.resetForm({});
    }
    
    setShowSnackBar(true);
    setLoading(false);
  }

  const handleOnSnackbarClose = () => {
    setShowSnackBar(false)
  }

  return (
    <Wrapper gutter>
      <Section
        heading="Got a question?"
        blurb="Ask us anything and we will get back to you ASAP"
      >
        <Snackbar 
          open={showSnackBar}
          autoHideDuration={5000}
          onClose={handleOnSnackbarClose}
        >
          <Alert 
            severity={snackbarStyle}
            onClose={handleOnSnackbarClose}
            action={
              <IconButton onClick={handleOnSnackbarClose} color="inherit" size="small">
                <CloseIcon />
              </IconButton>
            }
          > 
            {snackbarMessage} 
          </Alert>
        </Snackbar>

        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} className={classes.row}>
              <TextField 
                id="name" 
                placeholder="Name" 
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={Boolean(formik.errors.name)}                  
                helperText={formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={cn({[classes.nameRow]: true, [classes.row]: true})}>
              <TextField 
                id="email" 
                placeholder="Email" 
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={Boolean(formik.errors.email)}                  
                helperText={formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.row}>
              <TextField 
                id="contact" 
                placeholder="Contact Number" 
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.contact}
                error={Boolean(formik.errors.contact)}                  
                helperText={formik.errors.contact}
              />
            </Grid>            
            <Grid item xs={12} className={classes.row}>
              <Dropdown 
                id="enquiry"
                name="enquiry"
                question="What is your enquiry about"
                displayEmpty
                value={formik.values.enquiry}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.enquiry)}
                helperText={formik.errors.enquiry}
                variant="outlined"
                items={[
                  { value: "General Enquiry", label: "General Enquiry" },
                  { value: "Packages", label: "Packages" },
                  { value: "Make a booking", label: "Make a booking" },
                  { value: "Event Images", label: "Event Images" },
                ]}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className={classes.row}>
              <TextField 
                id="message" 
                placeholder="Tell us how we can help you" 
                variant="outlined"
                rows={8}
                multiline
                onChange={formik.handleChange}
                value={formik.values.message}
                error={Boolean(formik.errors.message)}                  
                helperText={formik.errors.message}
              />
            </Grid>
            <ActionButtons
              loading={loading}
              primaryText={loading ? "Submitting" : "Submit"}
              secondaryText="Clear"
              onPrimaryClick={formik.handleSubmit}
              onSecondaryClick={() => {}}
            />      
          </Grid>
        </form>
      </Section>
    </Wrapper>
  )  
}

export default ContactForm;